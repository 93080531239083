import { ISeasonPrices } from 'pages/Quote/Quote';
import {
  AppDatabase,
  Currencies,
  ICurrency,
  INewProperty,
  IUpstreamProperty,
} from 'services/appDatabase';
import { AdjustedResult } from 'stateManagement/Engine';
import BaseEngine from './base';
import {
  IAveragePriceChangeEngine,
  IAveragePriceChangeResult,
  IBenchmarkEngine,
  IOccupancyEngine,
  IOccupancyResult,
  IPriceAdjustedOccupancyEngine,
  IPricingEngine,
  IPricingResult,
  IRevenueEngine,
  IRevenueResult,
  ISeasonPricingEngine,
  IShortBreakEngine,
  IShortBreakResult,
  IStayTypeValue,
} from './types';

type IBenchmarkEngineConstructor = new (database: AppDatabase) => IBenchmarkEngine;
type IOccupancyEngineConstructor = new (database: AppDatabase) => IOccupancyEngine;
type IPricingEngineConstructor = new (database: AppDatabase) => IPricingEngine;
type IRevenueEngineConstructor = new (database: AppDatabase) => IRevenueEngine;
type IShortBreakEngineConstructor = new (database: AppDatabase) => IShortBreakEngine;
type IAveragePriceChangeEngineConstructor = new (
  database: AppDatabase,
) => IAveragePriceChangeEngine;
type IPriceAdjustedOccupancyEngineConstructor = new (
  database: AppDatabase,
) => IPriceAdjustedOccupancyEngine;
type ISeasonPricingEngineConstructor = new (database: AppDatabase) => ISeasonPricingEngine;

class TotalEngine extends BaseEngine {
  private benchmarkEngine: IBenchmarkEngine;
  private occupancyEngine: IOccupancyEngine;
  private pricingEngine: IPricingEngine;
  private revenueEngine: IRevenueEngine;
  private shortBreakEngine: IShortBreakEngine;
  private averagePriceChangeEngine: IAveragePriceChangeEngine;
  private priceAdjustedOccupancyEngine: IPriceAdjustedOccupancyEngine;
  private seasonPricingEngine: ISeasonPricingEngine;

  constructor(
    database: AppDatabase,
    BenchmarkEngine: IBenchmarkEngineConstructor,
    OccupancyEngine: IOccupancyEngineConstructor,
    PricingEngine: IPricingEngineConstructor,
    RevenueEngine: IRevenueEngineConstructor,
    ShortBreakEngine: IShortBreakEngineConstructor,
    AveragePriceChangeEngine: IAveragePriceChangeEngineConstructor,
    PriceAdjustedOccupancyEngine: IPriceAdjustedOccupancyEngineConstructor,
    SeasonPricingEngine: ISeasonPricingEngineConstructor,
  ) {
    super(database);
    this.benchmarkEngine = new BenchmarkEngine(this.db);
    this.occupancyEngine = new OccupancyEngine(this.db);
    this.pricingEngine = new PricingEngine(this.db);
    this.revenueEngine = new RevenueEngine(this.db);
    this.shortBreakEngine = new ShortBreakEngine(this.db);
    this.averagePriceChangeEngine = new AveragePriceChangeEngine(this.db);
    this.priceAdjustedOccupancyEngine = new PriceAdjustedOccupancyEngine(this.db);
    this.seasonPricingEngine = new SeasonPricingEngine(this.db);
  }

  public selectBenchmarkProperties(newProperty: INewProperty): Promise<IUpstreamProperty[]> {
    return this.benchmarkEngine.compute(newProperty);
  }

  public computeOccupancy(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
  ): Promise<IOccupancyResult[]> {
    return this.occupancyEngine.computeOccupancy(newProperty, benchmarkProperties);
  }

  public computePricing(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    currencies: Currencies,
  ): Promise<IPricingResult[]> {
    return this.pricingEngine.computePricing(newProperty, benchmarkProperties, currencies);
  }

  public computePricingN2S(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    currencies: Currencies,
  ): Promise<IPricingResult[]> {
    return this.pricingEngine.computePricingN2S(newProperty, benchmarkProperties, currencies);
  }

  public computePricingWithGuestCoeffs(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    currencies: Currencies,
  ): Promise<IPricingResult[]> {
    return this.pricingEngine.computePricingWithGuestCoeffs(
      newProperty,
      benchmarkProperties,
      currencies,
    );
  }

  public computeSeasonPrices(
    pricingResults: AdjustedResult<IPricingResult[]>,
    ownerCurrency: ICurrency,
  ): Promise<ISeasonPrices> {
    return this.seasonPricingEngine.computeSeasonPrices(pricingResults, ownerCurrency);
  }

  public computeSeasonPricesN2S(
    pricingResults: AdjustedResult<IPricingResult[]>,
    ownerCurrency: ICurrency,
  ): Promise<ISeasonPrices> {
    return this.seasonPricingEngine.computeSeasonPricesN2S(pricingResults, ownerCurrency);
  }

  public computeRevenue(
    pricingResults: IPricingResult[],
    occupancyResult: IOccupancyResult[],
    newProperty: INewProperty,
  ): Promise<IRevenueResult[]> {
    return this.revenueEngine.computeRevenue(pricingResults, occupancyResult, newProperty);
  }

  public computeShortBreak(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    pricingResult: IPricingResult[],
    priceDistribution?: IStayTypeValue[] | undefined,
  ): Promise<IShortBreakResult> {
    return this.shortBreakEngine.computeShortBreak(
      newProperty,
      benchmarkProperties,
      pricingResult,
      priceDistribution,
    );
  }

  public computeAPC(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    ownerShortBreak: IShortBreakResult,
    adjustedPricing: IPricingResult[],
    originalPricing: IPricingResult[],
  ): Promise<IAveragePriceChangeResult[]> {
    return this.averagePriceChangeEngine.computeAveragePriceChange(
      newProperty,
      benchmarkProperties,
      ownerShortBreak,
      adjustedPricing,
      originalPricing,
    );
  }

  public computePriceAdjustedOccupancy(
    newProperty: INewProperty,
    previousOccupancyResults: IOccupancyResult[],
    averagePriceChangeResults: IAveragePriceChangeResult[],
  ): Promise<IOccupancyResult[]> {
    return this.priceAdjustedOccupancyEngine.computePriceAdjustedOccupancy(
      newProperty,
      previousOccupancyResults,
      averagePriceChangeResults,
    );
  }
}

export default TotalEngine;
