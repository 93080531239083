import {
  FormValues,
  IOccupancySeasonAdjustment,
} from 'pages/Quote/AdjustOccupancyDialog/AdjustOccupancyDialog.novasol';
import { IOccupancyAdjustmentDialog } from 'pages/Quote/AdjustOccupancyDialog/types';
import { ISeason } from './appDatabase';
import { getYearOne } from './dates';
import { WEEK_LENGTH } from './engine/constants';
import { sumOccupancyWeeks } from './engine/occupancy';
import { IOccupancyResult, IOccupancyWeek, IRange } from './engine/types';
import { addIRanges, floorIRange, roundIRange, scalarIRanges } from './utils/iRange';

export interface ITwoYearOccupancySeason {
  season: ISeason;
  maxWeeks: {
    yearOne: number;
    yearTwo: number;
  };
  expectedWeeks: {
    yearOne: IRange;
    yearTwo: IRange;
  };
  expectedWeeksOriginal: {
    yearOne: IRange;
    yearTwo: IRange;
  };
}

export interface IOccupancySeason {
  season: ISeason;
  maxWeeks: number;
  expectedWeeks: IRange;
}

export interface ITwoYearOccupancySeasonMap {
  [seasonName: string]: ITwoYearOccupancySeason;
}

export interface IOccupancySeasonMap {
  [seasonName: string]: IOccupancySeason;
}

const getInitialTwoYearSeasonOccupancy = (): ITwoYearOccupancySeason => ({
  season: {
    name: '',
    uuid: '',
    color: '',
  },
  maxWeeks: {
    yearOne: 0,
    yearTwo: 0,
  },
  expectedWeeks: {
    yearOne: { highest: 0, lowest: 0 },
    yearTwo: { highest: 0, lowest: 0 },
  },
  expectedWeeksOriginal: {
    yearOne: { highest: 0, lowest: 0 },
    yearTwo: { highest: 0, lowest: 0 },
  },
});

const getInitialSeasonOccupancy = (): IOccupancySeason => ({
  season: {
    name: '',
    uuid: '',
    color: '',
  },
  maxWeeks: 0,
  expectedWeeks: { highest: 0, lowest: 0 },
});

export const getOccupancyBySeason = (occupancy: IOccupancyResult): IOccupancySeasonMap => {
  const occupancyWeeksYearOne = occupancy.weeks;

  const res: IOccupancySeasonMap = occupancyWeeksYearOne!.reduce(
    (acc: IOccupancySeasonMap, occupancyWeek) => {
      let seasonOccupancy: IOccupancySeason = acc[occupancyWeek.season.name];
      const weekOccupancyRange = occupancyWeek.commercialOccupancy;
      if (!seasonOccupancy) {
        seasonOccupancy = getInitialSeasonOccupancy();
        seasonOccupancy.season = occupancyWeek.season;
      }
      seasonOccupancy.expectedWeeks = addIRanges(seasonOccupancy.expectedWeeks, weekOccupancyRange);
      seasonOccupancy.maxWeeks += 1;

      return {
        ...acc,
        [occupancyWeek.season.name]: seasonOccupancy,
      };
    },
    {},
  );

  return res;
};

export const getTwoYearOccupancyBySeason = (
  occupancy: IOccupancyResult[],
  engineOccupancy: IOccupancyResult[],
): ITwoYearOccupancySeasonMap => {
  const yearOne = getYearOne(occupancy);
  const yearTwo = yearOne + 1;
  const occupancyWeeksYearOne = occupancy.find(
    (occupancyResult) => occupancyResult.year === yearOne,
  )!.weeks;
  const occupancyWeeksYearTwo = occupancy.find(
    (occupancyResult) => occupancyResult.year === yearTwo,
  )!.weeks;

  const res: ITwoYearOccupancySeasonMap = occupancyWeeksYearOne!.reduce(
    (acc: ITwoYearOccupancySeasonMap, occupancyWeek, currentIndex) => {
      let seasonOccupancy: ITwoYearOccupancySeason = acc[occupancyWeek.season.name];
      const weekOccupancyRange = occupancyWeek.commercialOccupancy;
      const engineWeekOccupancyRange = engineOccupancy[0].weeks![currentIndex].commercialOccupancy;

      if (!seasonOccupancy) {
        seasonOccupancy = getInitialTwoYearSeasonOccupancy();
        seasonOccupancy.season = occupancyWeek.season;
      }
      seasonOccupancy.expectedWeeksOriginal.yearOne = addIRanges(
        seasonOccupancy.expectedWeeks.yearOne,
        engineWeekOccupancyRange,
      );
      seasonOccupancy.expectedWeeks.yearOne = addIRanges(
        seasonOccupancy.expectedWeeks.yearOne,
        weekOccupancyRange,
      );
      seasonOccupancy.maxWeeks.yearOne += 1;

      return {
        ...acc,
        [occupancyWeek.season.name]: seasonOccupancy,
      };
    },
    {},
  );

  occupancyWeeksYearTwo!.forEach((occupancyWeek, currentIndex) => {
    let seasonOccupancy = res[occupancyWeek.season.name];
    const engineWeekOccupancyRange = engineOccupancy[1].weeks![currentIndex].commercialOccupancy;
    if (!seasonOccupancy) {
      res[occupancyWeek.season.name] = getInitialTwoYearSeasonOccupancy();
      seasonOccupancy = res[occupancyWeek.season.name];
      seasonOccupancy.season = occupancyWeek.season;
    }
    seasonOccupancy.expectedWeeksOriginal.yearTwo = addIRanges(
      seasonOccupancy.expectedWeeks.yearTwo,
      engineWeekOccupancyRange,
    );
    seasonOccupancy.expectedWeeks.yearTwo = addIRanges(
      seasonOccupancy.expectedWeeks.yearTwo,
      occupancyWeek.commercialOccupancy,
    );
    seasonOccupancy.maxWeeks.yearTwo += 1;
  });

  return res;
};

export const computeAdjustmentValues = (range: IRange, maxWeeks: number): IRange => {
  return scalarIRanges(range, 1 / maxWeeks);
};

export const createSeasonMap = (
  formValues: FormValues,
  seasonalOccupancy: ITwoYearOccupancySeasonMap,
  year: 'yearOne' | 'yearTwo',
  seasonNames: string[],
) =>
  seasonNames.reduce((mapping, season) => {
    const { maxWeeks } = seasonalOccupancy[season];
    return {
      ...mapping,
      [season]: computeAdjustmentValues(
        formValues.seasonOccupancyAdjustments[year][season],
        maxWeeks[year],
      ),
    };
  }, {});

export const applyAdjustmentsToOccupancy = (
  occupancy: IOccupancyResult[],
  adjustments: IOccupancySeasonAdjustment,
): IOccupancyAdjustmentDialog[] => {
  const yearOne = getYearOne(occupancy);
  return occupancy.map((occupancyYear) => ({
    year: occupancyYear.year,
    weeks: occupancyYear.weeks!.map((occupancyWeek) => {
      const year = occupancyYear.year === yearOne ? 'yearOne' : 'yearTwo';
      const adjustment = adjustments[year][occupancyWeek.season.name] || 0;
      return {
        ...occupancyWeek,
        commercialOccupancy: adjustment,
      };
    }),
    // the following are saved as BDM occupancy and are not used in the finalOccupancyEngine
    // At the end of the engine flow we always have and render the Owner occupancy (computed using the Engine/BDM weeks)
    bookings: { highest: 0, lowest: 0 },
    nights: { highest: 0, lowest: 0 },
    percentage: 0,
  }));
};

export const getOccupancyResultFromOccupancyWeeks = (
  occupancyWeeks: IOccupancyWeek[],
): IOccupancyResult => {
  if (occupancyWeeks.length === 0) {
    throw new Error('[getOccupancyResultFromOccupancyWeeks] Empty array of occupancy weeks given');
  }

  const year = occupancyWeeks[0].year;
  const bookedWeeksBySeason = getBookedWeeksBySeason(occupancyWeeks);
  const maxWeeksBySeason = getMaxWeeksBySeason(occupancyWeeks);
  const averageWeeklyOccupancyBySeason = getAverageWeeklyOccupancyBySeason(
    bookedWeeksBySeason,
    maxWeeksBySeason,
  );
  const averagedOccupancyWeeks = getOccupancyWeeks(occupancyWeeks, averageWeeklyOccupancyBySeason);
  const bookedWeeks = sumOccupancyWeeks(averagedOccupancyWeeks);

  // eslint-disable no-console
  console.log(`bookedWeeksBySeason (${year}):`, bookedWeeksBySeason);
  console.log(`maxWeeksBySeason (${year}):`, maxWeeksBySeason);
  // eslint-enable no-console

  return {
    year,
    bookings: bookedWeeks,
    nights: scalarIRanges(bookedWeeks, WEEK_LENGTH),
    weeks: averagedOccupancyWeeks,
  };
};

const getMaxWeeksBySeason = (weeksWithSeason: Array<{ season: ISeason }>) =>
  weeksWithSeason.reduce((maxWeeksBySeason, week) => {
    const maxWeeks = maxWeeksBySeason[week.season.name] || 0;
    return { ...maxWeeksBySeason, [week.season.name]: maxWeeks + 1 };
  }, {} as { [seasonName: string]: number });

const getBookedWeeksBySeason = (weeks: Array<{ season: ISeason; commercialOccupancy: IRange }>) =>
  weeks.reduce((bookedWeeksBySeason, week) => {
    const bookedWeeks = bookedWeeksBySeason[week.season.name] || { highest: 0, lowest: 0 };
    return {
      ...bookedWeeksBySeason,
      [week.season.name]: addIRanges(bookedWeeks, week.commercialOccupancy),
    };
  }, {} as { [seasonName: string]: IRange });

const getAverageWeeklyOccupancyBySeason = (
  bookedWeeksBySeason: { [seasonName: string]: IRange },
  maxWeeksBySeason: { [seasonName: string]: number },
) =>
  Object.keys(bookedWeeksBySeason).reduce((averageWeekOccupancyBySeason, seasonName) => {
    const bookedWeeks = floorIRange(roundIRange(bookedWeeksBySeason[seasonName], 3));
    const maxWeeks = maxWeeksBySeason[seasonName];
    if (maxWeeks === 0) {
      throw new Error(
        `[getAverageWeeklyOccupancyBySeason] Max weeks for season ${seasonName} is 0`,
      );
    }
    return {
      ...averageWeekOccupancyBySeason,
      [seasonName]: scalarIRanges(bookedWeeks, 1 / maxWeeks),
    };
  }, {} as { [seasonName: string]: IRange });

const getOccupancyWeeks = (
  occupancyWeeks: IOccupancyWeek[],
  averageWeeklyOCcupancyBySeason: { [seasonName: string]: IRange },
): IOccupancyWeek[] => {
  return occupancyWeeks.map((week) => ({
    year: week.year,
    commercialOccupancy: averageWeeklyOCcupancyBySeason[week.season.name],
    season: week.season,
    weekNumber: week.weekNumber,
  }));
};
