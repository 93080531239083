import { ISeason } from './appDatabase';
import { PricingEngineException } from './engine/exceptions';
import { IWeekPricingData } from './engine/types';

export const findSeasonAPrice = (
  seasons: ISeason[],
  pricingResult: IWeekPricingData[],
): number | undefined => {
  return findSeasonPrice(seasons, 'A', pricingResult);
};

export const findSeasonAPriceN2S = (
  seasons: ISeason[],
  pricingResult: IWeekPricingData[],
): number | undefined => {
  return findSeasonPriceN2S(seasons, 'A', pricingResult);
};

export const findMidlinePriceForSeasonUUID = (
  seasonUUID: string,
  pricingResult: IWeekPricingData[],
): number | undefined => {
  const seasonWeek = pricingResult.find((week) => week.season === seasonUUID);
  return seasonWeek ? seasonWeek.price : undefined;
};

export const findMidlineSalesPriceForSeasonUUID = (
  seasonUUID: string,
  pricingResult: IWeekPricingData[],
): number | undefined => {
  const seasonWeek = pricingResult.find((week) => week.season === seasonUUID);
  return seasonWeek?.salesPrice;
};

export const findSeasonPrice = (
  seasons: ISeason[],
  seasonName: string,
  pricingResult: IWeekPricingData[],
): number | undefined => {
  const season = seasons.find((seasonElement) => seasonElement.name === seasonName);
  if (!season) {
    return;
  }
  const seasonWeek = pricingResult.find((week) => week.season === season.uuid);
  return seasonWeek ? seasonWeek.price : undefined;
};

export const findSeasonPriceN2S = (
  seasons: ISeason[],
  seasonName: string,
  pricingResult: IWeekPricingData[],
): number | undefined => {
  const season = seasons.find((seasonElement) => seasonElement.name === seasonName);
  if (!season) {
    return;
  }
  const seasonWeek = pricingResult.find((week) => week.season === season.uuid);
  return seasonWeek ? seasonWeek.salesPrice : undefined;
};

export const calculateAveragePriceChange = (originalPrice: number, latestPrice: number) =>
  (latestPrice - originalPrice) / originalPrice;

export const applyAveragePriceChange = (weekPricingData: IWeekPricingData, apc: number) => ({
  ...weekPricingData,
  price: weekPricingData.price * (1 + apc),
});

export const applyAveragePriceChangeN2S = (weekPricingData: IWeekPricingData, apc: number) => ({
  ...weekPricingData,
  salesPrice: (weekPricingData.salesPrice ?? 0) * (1 + apc),
});

export const applySeasonPrice = (
  weekPricingData: IWeekPricingData,
  seasons: ISeason[],
  seasonPrices: { [seasonName: string]: number },
) => {
  const season = seasons.find((s) => s.uuid === weekPricingData.season);
  if (!season) {
    throw new PricingEngineException(
      `Could not find season ${weekPricingData.season} in the weekPricingData`,
    );
  }
  const seasonPrice = seasonPrices[season.name];
  return { ...weekPricingData, price: seasonPrice };
};

export const applySeasonPriceN2S = (
  weekPricingData: IWeekPricingData,
  seasons: ISeason[],
  seasonPrices: { [seasonName: string]: number },
) => {
  const season = seasons.find((s) => s.uuid === weekPricingData.season);
  if (!season) {
    throw new PricingEngineException(
      `Could not find season ${weekPricingData.season} in the weekPricingData`,
    );
  }
  const seasonPrice = seasonPrices[season.name];
  return { ...weekPricingData, salesPrice: seasonPrice };
};
