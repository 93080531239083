import { IRange } from 'services/engine/types';
import { capBetween, round } from './numberUtils';

export const applyPercentageToIRange = (range: IRange, percentage: number) => ({
  lowest: Math.floor(range.lowest * (1 + percentage / 100)),
  highest: Math.floor(range.highest * (1 + percentage / 100)),
});

export const boundaryAddIRanges = (rangeA: IRange, rangeB: IRange, min = 0, max = 1) => ({
  lowest: capBetween(rangeA.lowest + rangeB.lowest, min, max),
  highest: capBetween(rangeA.highest + rangeB.highest, min, max),
});

export const addIRanges = (rangeA: IRange, rangeB: IRange) => ({
  lowest: rangeA.lowest + rangeB.lowest,
  highest: rangeA.highest + rangeB.highest,
});

export const addScalarToIRange = (range: IRange, scalar: number) => ({
  lowest: range.lowest + scalar,
  highest: range.highest + scalar,
});

export const minusIRanges = (rangeA: IRange, rangeB: IRange) => ({
  lowest: rangeA.lowest - rangeB.lowest,
  highest: rangeA.highest - rangeB.highest,
});

export const roundIRange = (range: IRange, dec = 0) => ({
  lowest: round(range.lowest, dec),
  highest: round(range.highest, dec),
});

export const floorCeilIRange = (range: IRange) => ({
  lowest: Math.floor(range.lowest),
  highest: Math.ceil(range.highest),
});

export const floorIRange = (range: IRange) => ({
  lowest: Math.floor(range.lowest),
  highest: Math.floor(range.highest),
});

export const scalarIRanges = (range: IRange, coef: number) => ({
  lowest: range.lowest * coef,
  highest: range.highest * coef,
});
