import { INewProperty } from 'services/appDatabase';
import { scalarIRanges } from 'services/utils/iRange';
import BaseEngine from './base';
import { PricingEngineException } from './exceptions';
import {
  IAveragePriceChangeResult,
  IOccupancyResult,
  IPriceAdjustedOccupancyEngine,
} from './types';

class PriceAdjustedOccupancyEngine extends BaseEngine implements IPriceAdjustedOccupancyEngine {
  async computePriceAdjustedOccupancy(
    newProperty: INewProperty,
    previousOccupancyResults: IOccupancyResult[],
    averagePriceChangeResults: IAveragePriceChangeResult[],
  ): Promise<IOccupancyResult[]> {
    const elasticity = await this.getElasticity(newProperty);
    const lengthOfStay = await this.getLengthOfStay(newProperty);

    const result = previousOccupancyResults.map((occupancyResult) => {
      const averagePriceChangeResult = averagePriceChangeResults.find(
        (apc) => apc.year === occupancyResult.year,
      );
      if (!averagePriceChangeResult) {
        throw new PricingEngineException(
          `[PriceAdjustedOccupancy] Could not find an average price change for year ${occupancyResult.year}`,
        );
      }
      const adjustedNights = scalarIRanges(
        occupancyResult.nights,
        1 + averagePriceChangeResult.averagePriceChange * elasticity,
      );
      const adjustedBookings = {
        lowest: adjustedNights.lowest / lengthOfStay,
        highest: adjustedNights.highest / lengthOfStay,
      };
      return {
        year: occupancyResult.year,
        nights: adjustedNights,
        bookings: adjustedBookings,
      };
    });

    // eslint-disable-next-lineno-console
    console.log('Price adjsuted occupancy: ', result);

    return result;
  }

  protected async getElasticity(newProperty: INewProperty): Promise<number> {
    const { elasticity } = await this.getCalendar();
    return elasticity;
  }
}

export default PriceAdjustedOccupancyEngine;
