import sortBy from 'lodash/sortBy';
import { INewProperty } from 'services/appDatabase';
import { addIRanges, scalarIRanges } from 'services/utils/iRange';
import BaseEngine from '../base';
import { PricingEngineException } from '../exceptions';
import { IOccupancyResult, IPricingResult, IRange, IRevenueEngine, IRevenueResult } from '../types';

class NovasolRevenueEngine extends BaseEngine implements IRevenueEngine {
  public async computeRevenue(
    pricingResults: IPricingResult[],
    occupancyResults: IOccupancyResult[],
    newProperty: INewProperty,
  ): Promise<IRevenueResult[]> {
    const revenueResult = occupancyResults.map((occupancyResult) => {
      const year = occupancyResult.year;
      const pricingResult = pricingResults.find(
        (pricingResultElement) => pricingResultElement.year === year,
      );
      if (
        !pricingResult ||
        !occupancyResult.weeks ||
        occupancyResult.weeks.length !== pricingResult.yearData.length
      ) {
        throw new PricingEngineException(
          `[RevenueEngine] Different number of weeks for occupancy and pricing`,
        );
      }

      const sortedOccupancyWeeks = sortBy(occupancyResult.weeks, ['weekNumber']);
      const sortedPricingWeeks = sortBy(pricingResult.yearData, ['weekNumber']);

      const revenueWeeks = sortedOccupancyWeeks.map((item, index) =>
        scalarIRanges(item.commercialOccupancy, sortedPricingWeeks[index].price),
      );

      const resultRange: IRange = revenueWeeks.reduce(
        (acc, revenueWeek) => addIRanges(acc, revenueWeek),
        { lowest: 0, highest: 0 },
      );

      return {
        year,
        ...resultRange,
      };
    });

    // eslint-disable-next-lineno-console
    console.log('Revenue result', revenueResult);

    return revenueResult;
  }
}

export default NovasolRevenueEngine;
