import { MARKUP_COUNTRY_FALLBACK } from 'constants/countries';
import _flatMap from 'lodash/flatMap';
import { IMarkupAdjustedValues } from 'organisms/Markup/Markup';
import { IMarkupResult, IWeekPricingData } from 'services/engine/types';
import { EURO_CODE } from 'stateManagement/Currency/constants';
import appDatabase, {
  ICountry,
  ICurrency,
  ICurrencyFee,
  IMarkupSeasonalExtras,
  IMarkupValue,
  INewProperty,
} from '../appDatabase';
import { genericCurrencyConvert } from '../currency/conversion';
import { getSeasonsForCalendar } from '../engine/utils';
import {
  getCurrencyRateOfExchange,
  getFilteredMarkupSeasonalExtras,
  getHomeInsuranceValues,
  getMarkupCacadeForSeason,
  getMarkupCascades,
  getPriceMappingForCalendar,
  IMarketTypes,
} from './markupCalculator.getters';
import {
  buildSeasonMidlinePriceIndex,
  getCancellationInsurance,
  roundGuestPrice,
} from './markupCalculator.helpers';

export async function calculateMarkup(
  newProperty: INewProperty,
  engineCurrency: ICurrency,
  ownerCurrency: ICurrency,
  guestCurrency: ICurrency,
  euroCurrency: ICurrency,
  pricingResultsYearOne: IWeekPricingData[],
  markupAdjustments?: IMarkupAdjustedValues,
): Promise<IMarkupResult> {
  const country = await appDatabase.countries.toCollection().first();

  if (country === undefined) {
    throw new Error(`[markupCalculator] Failed to find country`);
  }

  const markupCurrencyFees = await appDatabase.markupCurrencyFee.toArray();
  const markupHomeInsurance = await appDatabase.markupHomeInsurance.toArray();
  const priceMappings = await appDatabase.priceIdMappings.toArray();
  const markupSeasonalExtras = await appDatabase.markupSeasonalExtras.toArray();

  const seasons = await getSeasonsForCalendar(newProperty.calendar, [
    newProperty.firstAvailable.year,
  ]);

  const currencyROE = {
    productMarket: getCurrencyRateOfExchange(ownerCurrency, guestCurrency, markupCurrencyFees),
    salesMarket: getCurrencyRateOfExchange(ownerCurrency, euroCurrency, markupCurrencyFees),
  };

  const filteredHomeInsurances = getHomeInsuranceValues(
    country.code,
    MARKUP_COUNTRY_FALLBACK.INSURANCE,
    markupHomeInsurance.filter((i) => i.year === newProperty.firstAvailable.year),
  );

  const filteredPriceMapping = await getPriceMappingForCalendar(
    newProperty.calendar,
    priceMappings,
    newProperty.firstAvailable.year.toString(),
  );
  const filteredSeasonalExtras = getFilteredMarkupSeasonalExtras(
    country.code,
    filteredPriceMapping,
    markupSeasonalExtras,
  );

  // eslint-disable-next-lineno-console
  console.log('currencyROE', currencyROE);
  // eslint-disable-next-lineno-console
  console.log('filteredHomeInsurances', filteredHomeInsurances);
  // eslint-disable-next-lineno-console
  console.log('filteredSeasonalExtras', filteredSeasonalExtras);

  const convertToOwnerCurrency = genericCurrencyConvert(engineCurrency, ownerCurrency);
  const seasonMidlinePriceIndexInOwnerCurrency = buildSeasonMidlinePriceIndex(
    seasons,
    pricingResultsYearOne,
    convertToOwnerCurrency,
  );

  let markupCascades: Array<IMarketTypes<IMarkupValue>> = await getMarkupCascades(
    seasonMidlinePriceIndexInOwnerCurrency,
    newProperty.cascadeType,
    country,
    newProperty.firstAvailable.year,
  );

  if (markupAdjustments) {
    markupCascades = _flatMap(markupAdjustments, (adjustedProductMarketMarkup, seasonName) => {
      const initialMarkup = markupCascades.find(
        (markupCascade) => markupCascade.productMarket.seasonName === seasonName,
      );
      const adjustmentFactor = adjustedProductMarketMarkup / initialMarkup!.productMarket.markup;
      // eslint-disable-next-lineno-console
      console.log(`[Adj] Markup factor (season ${seasonName}): ${adjustmentFactor}`);

      return {
        productMarket: { seasonName, markup: adjustedProductMarketMarkup },
        salesMarket: { seasonName, markup: adjustmentFactor * initialMarkup!.salesMarket.markup },
      };
    });
  }

  // eslint-disable-next-lineno-console
  console.log('MarkupCascades', markupCascades);

  const result: IMarkupResult = Object.keys(seasonMidlinePriceIndexInOwnerCurrency).reduce(
    (seasonalPricesWithMarkup, seasonName) => {
      const midLinePriceInOwnerCurrency = seasonMidlinePriceIndexInOwnerCurrency[seasonName];
      const markupCascadeForSeason = getMarkupCacadeForSeason(markupCascades, seasonName);
      const markets: Array<keyof IMarketTypes<any>> = ['productMarket', 'salesMarket'];
      const [
        productMarketPriceWithMarkupInProductMarketCurrency,
        salesMarketPriceWithMarkupInSalesMarketCurrency,
      ] = markets.map((market) => {
        return calculatePriceWithMarkupForSeason(
          seasonName,
          midLinePriceInOwnerCurrency,
          markupCascadeForSeason[market],
          filteredSeasonalExtras[market],
          currencyROE[market],
          filteredHomeInsurances[market],
          country,
        );
      });

      // eslint-disable-next-lineno-console
      console.log(
        `Price in ${guestCurrency.name} with markup for season ${seasonName}: ${productMarketPriceWithMarkupInProductMarketCurrency}`,
      );
      // eslint-disable-next-lineno-console
      console.log(
        `Price in ${euroCurrency.name} with markup for season ${seasonName}: ${salesMarketPriceWithMarkupInSalesMarketCurrency}`,
      );

      return {
        ...seasonalPricesWithMarkup,
        [seasonName]: {
          productMarket: {
            guestPrice: {
              value: roundGuestPrice(productMarketPriceWithMarkupInProductMarketCurrency),
              currencyCode: guestCurrency.code,
            },
            markup: markupCascadeForSeason.productMarket.markup,
          },
          salesMarket: {
            guestPrice: {
              value: roundGuestPrice(salesMarketPriceWithMarkupInSalesMarketCurrency),
              currencyCode: EURO_CODE,
            },
            markup: markupCascadeForSeason.salesMarket.markup,
          },
        },
      };
    },
    {} as IMarkupResult,
  );

  // eslint-disable-next-lineno-console
  console.log('MarkupResults', result);

  return result;
}

export const calculatePriceWithMarkupForSeason = (
  seasonName: string,
  midLinePriceInOwnerCurrency: number,
  markupCascadeForSeason: IMarkupValue,
  filteredSeasonalExtras: IMarkupSeasonalExtras[],
  currencyROE: ICurrencyFee,
  homeInsurance: number,
  country: ICountry,
): number => {
  const seasonalExtrasForCurrentSeason = filteredSeasonalExtras.find(
    (markupExtras) => markupExtras.season === seasonName,
  );

  if (!seasonalExtrasForCurrentSeason) {
    throw new Error(`[markupCalculator] No markup seasonal extras found for ${seasonName}`);
  }

  const cancellationInsurance = getCancellationInsurance(seasonalExtrasForCurrentSeason);

  // Denmark needs a slightly different calculation from the rest
  if (country.code === '208') {
    return (
      (midLinePriceInOwnerCurrency + homeInsurance) *
        // TODO ---- This becomes single value ------
        markupCascadeForSeason.markup *
        currencyROE.conversion *
        cancellationInsurance +
      seasonalExtrasForCurrentSeason.minorDamages
    );
  }
  return (
    midLinePriceInOwnerCurrency *
      markupCascadeForSeason.markup *
      currencyROE.conversion *
      cancellationInsurance +
    seasonalExtrasForCurrentSeason.minorDamages +
    homeInsurance
  );
};
