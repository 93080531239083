import { DateTime } from 'luxon';
import { addIRanges, addScalarToIRange, roundIRange } from 'services/utils/iRange';
import {
  FeatureValueSet,
  IBookingPerMonth,
  IBookingWindowPenalty,
  INewProperty,
  IOccupancyCoefficient,
  IUpstreamProperty,
} from '../appDatabase';
import BaseEngine from './base';
import { IOccupancyEngine, IOccupancyResult, IOccupancyWeek, IRange } from './types';
import { getLeadTime, getPercentile } from './utils';

export interface IMonthlyOccupancy {
  open: boolean;
  leadTime: number;
  bookingPenalty: number;
  year: number;
  month: number;
  commercialOccupancy: IRange;
}

export const sumOccupancyWeeks = (occupancyWeeks: IOccupancyWeek[]): IRange =>
  roundIRange(
    occupancyWeeks.reduce(
      (acc, occupancyWeek) => addIRanges(acc, occupancyWeek.commercialOccupancy),
      {
        lowest: 0,
        highest: 0,
      },
    ),
    3,
  );

export const sumOccupancyWeeksForYear = (
  occupancyWeeks: IOccupancyWeek[],
  year: number,
): IRange => {
  return occupancyWeeks
    .filter((week) => week.year === year)
    .reduce((acc, occupancyWeek) => addIRanges(acc, occupancyWeek.commercialOccupancy), {
      lowest: 0,
      highest: 0,
    });
};

abstract class OccupancyEngine extends BaseEngine implements IOccupancyEngine {
  protected lowestPercentile = 0.5;
  protected highestPercentile = 0.7;

  public abstract computeOccupancy(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
  ): Promise<IOccupancyResult[]>;

  protected capMaximumOccupancy(occupancy: IRange): IRange {
    return {
      lowest: Math.min(occupancy.lowest, 1),
      highest: Math.min(occupancy.highest, 1),
    };
  }

  protected async getEstimatedOccupancy(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    pricingRegionCode: string,
  ): Promise<IRange> {
    const occupancyCoefficients: IOccupancyCoefficient[] = await this.selectOccupancyCoefficients(
      pricingRegionCode,
    );

    const adjustedOccupancy: IRange = this.getAdjustedOCcupancy(
      occupancyCoefficients,
      benchmarkProperties,
    );
    // eslint-disable-next-lineno-console
    console.log('Naked Occupancy', adjustedOccupancy);

    const myPropertyWeightedCoefficients: FeatureValueSet = this.mapToFeatureValues(
      newProperty,
      occupancyCoefficients,
    );

    const myPropertySummedCoefficients = this.sumOfFeatureValueSet(myPropertyWeightedCoefficients);

    // eslint-disable no-console
    console.log('myPropertyFeatureFactors: ', newProperty.featureFactors);
    console.log('myPropertyWeightedCoefficients', myPropertyWeightedCoefficients);
    console.log('myPropertySummedCoefficients', myPropertySummedCoefficients);
    // eslint-enable no-console

    const estimatedOccupancy = this.capMaximumOccupancy(
      addScalarToIRange(adjustedOccupancy, myPropertySummedCoefficients),
    );

    // eslint-disable-next-lineno-console
    console.log('Estimated Occupancy (naked + coeffs)', estimatedOccupancy);

    return estimatedOccupancy;
  }

  protected getAdjustedOCcupancy(
    occupancyCoefficients: IOccupancyCoefficient[],
    benchmarkProperties: IUpstreamProperty[],
  ): IRange {
    // eslint-disable-next-lineno-console
    console.log('benchmarkProperties', benchmarkProperties);

    const benchmarkPropertiesWeightedCoefficients: FeatureValueSet[] = benchmarkProperties.map(
      (property: IUpstreamProperty) => this.mapToFeatureValues(property, occupancyCoefficients),
    );

    // eslint-disable-next-lineno-console
    console.log('benchmarkPropertiesWeightedCoefficients', benchmarkPropertiesWeightedCoefficients);

    const benchmarkPropertiesSummedCoefficients: number[] =
      benchmarkPropertiesWeightedCoefficients.map(this.sumOfFeatureValueSet);
    const benchmarkPropertiesAdjustedOccupancies = benchmarkProperties.map(
      (property: IUpstreamProperty, index: number) =>
        property.commercialOccupancy - benchmarkPropertiesSummedCoefficients[index],
    );

    // eslint-disable-next-lineno-console
    console.log('benchmarkPropertiesSummedCoefficients', benchmarkPropertiesSummedCoefficients);
    // eslint-disable-next-lineno-console
    console.log('benchmarkPropertiesNakedOccupancies', benchmarkPropertiesAdjustedOccupancies);

    return {
      lowest: getPercentile(benchmarkPropertiesAdjustedOccupancies, this.lowestPercentile),
      highest: getPercentile(benchmarkPropertiesAdjustedOccupancies, this.highestPercentile),
    };
  }

  protected getAvailableNightsPerYear(newProperty: INewProperty): number {
    return newProperty.firstAvailable.daysInYear;
  }

  protected async selectBookingPerMonth(pricingRegion: string): Promise<IBookingPerMonth[]> {
    const bookingPerMonth = await this.db.bookingPerMonth
      .where('pricingRegionCode')
      .equals(pricingRegion);
    return bookingPerMonth.toArray();
  }

  protected async selectPenalty(
    departMonth: number,
    leadTime: number,
    calendarUUID: string,
  ): Promise<IBookingWindowPenalty | undefined> {
    return await this.db.bookingWindowPenalty.get({
      departMonth,
      leadTime,
      calendar: calendarUUID,
    });
  }

  protected async getAllBookingPenalties(
    weeks: Array<{ year: number; month: number; weekNumber?: number }>,
    goLive: DateTime,
    calendarUUID: string,
    weeklyPrecision = false,
  ): Promise<IBookingWindowPenalty[]> {
    const DEFAULT_BOOKING_PENALTY = {
      penalty: 1,
      leadTime: 0,
      departMonth: 1,
      calendar: calendarUUID,
    };

    let previousPenalty: Promise<IBookingWindowPenalty | undefined> = Promise.resolve({
      ...DEFAULT_BOOKING_PENALTY,
    });

    const bookingPenalties = weeks.map((element) => {
      const elementDate = weeklyPrecision
        ? DateTime.fromObject({ weekYear: element.year, weekNumber: element.weekNumber })
        : DateTime.local(element.year, element.month, 1);
      if (!elementDate.invalidReason) {
        const leadTime = getLeadTime(elementDate, goLive);
        const departMonth = elementDate.month;
        previousPenalty = this.selectPenalty(departMonth, leadTime, calendarUUID);
      }

      return previousPenalty;
    });

    const result = await Promise.all(bookingPenalties);
    return result.map((bookingPenalty) =>
      bookingPenalty ? bookingPenalty : { ...DEFAULT_BOOKING_PENALTY },
    );
  }

  protected getYearlyCommercialOccupancy = (
    monthlyOccupancyList: IMonthlyOccupancy[],
    year: number,
  ): IRange =>
    monthlyOccupancyList.reduce(
      (acc: IRange, value: IMonthlyOccupancy) => ({
        lowest: acc.lowest + (value.year === year ? value.commercialOccupancy.lowest : 0),
        highest: acc.highest + (value.year === year ? value.commercialOccupancy.highest : 0),
      }),
      { lowest: 0, highest: 0 },
    );

  protected async selectOccupancyCoefficients(
    pricingRegion: string,
  ): Promise<IOccupancyCoefficient[]> {
    const coefficients = await this.db.occupancyCoefficients
      .where('pricingRegionCode')
      .equals(pricingRegion);
    return coefficients.toArray();
  }
}

export default OccupancyEngine;
