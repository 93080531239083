import { COUNTRY_CODES, MARKUP_COUNTRY_FALLBACK } from 'constants/countries';
import _flatMap from 'lodash/flatMap';
import { IMarkupAdjustedValues } from 'organisms/Markup/Markup';
import { IMarkupResult, IRevenueResult, IWeekPricingData } from 'services/engine/types';
import { EURO_CODE } from 'stateManagement/Currency/constants';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import appDatabase, {
  ICurrency,
  ICurrencyFee,
  IMarkupSeasonalExtras,
  IMarkupValue,
  INewProperty,
} from '../appDatabase';
import { genericCurrencyConvert } from '../currency/conversion';
import { getSeasonsForCalendar } from '../engine/utils';
import {
  getCurrencyRateOfExchange,
  getFilteredMarkupSeasonalExtras,
  getHomeInsuranceValues,
  getMarkupCacadeForSeason,
  getPriceMappingForCalendar,
  IMarketTypes,
} from './markupCalculator.getters';
import {
  buildSeasonMidlinePriceIndex,
  getCancellationInsurance,
  roundGuestPrice,
} from './markupCalculator.helpers';
import { getMarkupCascadesForDenmark } from './markupCalculatorDenmark.getters';

export async function calculateMarkupForDenmark(
  newProperty: INewProperty,
  engineCurrency: ICurrency,
  ownerCurrency: ICurrency,
  guestCurrency: ICurrency,
  euroCurrency: ICurrency,
  pricingResultsYearOne: IWeekPricingData[],
  quoteFeatures: IBrandFeatures,
  revenueResults: IRevenueResult[],
  markupAdjustments?: IMarkupAdjustedValues,
): Promise<IMarkupResult> {
  const markupCurrencyFees = await appDatabase.markupCurrencyFee.toArray();
  const markupHomeInsurance = await appDatabase.markupHomeInsurance.toArray();
  const priceMappings = await appDatabase.priceIdMappings.toArray();
  const markupSeasonalExtras = await appDatabase.markupSeasonalExtras.toArray();
  const seasons = await getSeasonsForCalendar(newProperty.calendar, [2019, 2020, 2021]);

  const currencyROE = {
    productMarket: getCurrencyRateOfExchange(ownerCurrency, guestCurrency, markupCurrencyFees),
    salesMarket: getCurrencyRateOfExchange(ownerCurrency, euroCurrency, markupCurrencyFees),
  };

  const filteredHomeInsurances = getHomeInsuranceValues(
    COUNTRY_CODES.DENMARK,
    MARKUP_COUNTRY_FALLBACK.INSURANCE,
    markupHomeInsurance,
  );

  const filteredPriceMapping = await getPriceMappingForCalendar(
    newProperty.calendar,
    priceMappings,
    newProperty.firstAvailable.year.toString(),
  );
  const filteredSeasonalExtras = getFilteredMarkupSeasonalExtras(
    COUNTRY_CODES.DENMARK,
    filteredPriceMapping,
    markupSeasonalExtras,
  );

  // eslint-disable-next-lineno-console
  console.log('currencyROE', currencyROE);
  // eslint-disable-next-lineno-console
  console.log('filteredHomeInsurances', filteredHomeInsurances);
  // eslint-disable-next-lineno-console
  console.log('filteredSeasonalExtras', filteredSeasonalExtras);

  const convertToOwnerCurrency = genericCurrencyConvert(engineCurrency, ownerCurrency);

  // HACK TO REMOVE SEASON B FOR 2021 CALENDARS 1 & 2
  // Will need to find a proper solution for when a calendar in a year doesn't contain all seasons.
  let filteredSeasons = seasons;
  const calendar = await appDatabase.calendar
    .filter((c) => c.uuid === newProperty.calendar)
    .first();
  const noSeasonBCalendars = ['01', '02'];
  if (calendar && noSeasonBCalendars.includes(calendar.name)) {
    filteredSeasons = seasons.filter((s) => s.name !== 'B');
  }
  // END HACK

  const seasonMidlinePriceIndexInOwnerCurrency = buildSeasonMidlinePriceIndex(
    filteredSeasons,
    pricingResultsYearOne,
    convertToOwnerCurrency,
  );

  let markupCascades: Array<IMarketTypes<IMarkupValue>> = await getMarkupCascadesForDenmark(
    seasonMidlinePriceIndexInOwnerCurrency,
    quoteFeatures,
    revenueResults,
    newProperty.areaCode,
  );

  if (markupAdjustments) {
    markupCascades = _flatMap(markupAdjustments, (adjustedProductMarketMarkup, seasonName) => {
      const initialMarkup = markupCascades.find(
        (markupCascade) => markupCascade.productMarket.seasonName === seasonName,
      );
      const adjustmentFactor = adjustedProductMarketMarkup / initialMarkup!.productMarket.markup;
      // eslint-disable-next-lineno-console
      console.log(`[Adj] Markup factor (season ${seasonName}): ${adjustmentFactor}`);

      return {
        productMarket: { seasonName, markup: adjustedProductMarketMarkup },
        salesMarket: { seasonName, markup: adjustmentFactor * initialMarkup!.salesMarket.markup },
      };
    });
  }

  const result: IMarkupResult = Object.keys(seasonMidlinePriceIndexInOwnerCurrency).reduce(
    (seasonalPricesWithMarkup, seasonName) => {
      const midLinePriceInOwnerCurrency = seasonMidlinePriceIndexInOwnerCurrency[seasonName];
      const markupCascadeForSeason = getMarkupCacadeForSeason(markupCascades, seasonName);
      const markets: Array<keyof IMarketTypes<any>> = ['productMarket', 'salesMarket'];
      const [
        productMarketPriceWithMarkupInProductMarketCurrency,
        salesMarketPriceWithMarkupInSalesMarketCurrency,
      ] = markets.map((market) => {
        const priceWithMarkup = calculateDanishPriceWithMarkupForSeason(
          seasonName,
          midLinePriceInOwnerCurrency,
          markupCascadeForSeason[market],
          filteredSeasonalExtras[market],
          currencyROE[market],
          filteredHomeInsurances[market],
        );

        return priceWithMarkup;
      });
      // eslint-disable-next-lineno-console
      console.log(
        `Price in DKK with markup for season ${seasonName}: ${productMarketPriceWithMarkupInProductMarketCurrency}`,
      );
      // eslint-disable-next-lineno-console
      console.log(
        `Price in EUR with markup for season ${seasonName}: ${salesMarketPriceWithMarkupInSalesMarketCurrency}`,
      );

      return {
        ...seasonalPricesWithMarkup,
        [seasonName]: {
          productMarket: {
            guestPrice: {
              value: roundGuestPrice(productMarketPriceWithMarkupInProductMarketCurrency),
              currencyCode: guestCurrency.code,
            },
            markup: markupCascadeForSeason.productMarket.markup,
          },
          salesMarket: {
            guestPrice: {
              value: roundGuestPrice(salesMarketPriceWithMarkupInSalesMarketCurrency),
              currencyCode: EURO_CODE,
            },
            markup: markupCascadeForSeason.salesMarket.markup,
          },
        },
      };
    },
    {} as IMarkupResult,
  );

  // eslint-disable-next-lineno-console
  console.log('MarkupResults', result);

  return result;
}

export const calculateDanishPriceWithMarkupForSeason = (
  seasonName: string,
  midLinePrice: number,
  markupCascadeForSeason: IMarkupValue,
  filteredSeasonalExtras: IMarkupSeasonalExtras[],
  currencyROE: ICurrencyFee,
  homeInsurance: number,
): number => {
  const seasonalExtrasForCurrentSeason = filteredSeasonalExtras.find(
    (markupExtras) => markupExtras.season === seasonName,
  );

  if (!seasonalExtrasForCurrentSeason) {
    throw new Error(`[markupCalculator] No markup seasonal extras found for ${seasonName}`);
  }

  const cancellationInsurance = getCancellationInsurance(seasonalExtrasForCurrentSeason);

  return (
    (midLinePrice + homeInsurance) *
      markupCascadeForSeason.markup *
      currencyROE.conversion *
      cancellationInsurance +
    seasonalExtrasForCurrentSeason.minorDamages
  );
};
