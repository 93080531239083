import { COUNTRY_CODES, MARKUP_COUNTRY_FALLBACK } from 'constants/countries';
import { HOUSE_TYPES } from 'constants/houseTypes';
import { POOL_TYPES } from 'molecules/featureComponents/novasol/Pooltype';
import { SAUNA_TYPES } from 'molecules/featureComponents/novasol/Sauna';
import { WHIRLPOOL_TYPES } from 'molecules/featureComponents/novasol/Whirlpool';
import appDatabase, { IMarkupData } from 'services/appDatabase';
import { IRevenueResult, ISeasonalPrices } from 'services/engine/types';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IMarketTypes } from './markupCalculator.getters';
import { getRevenueObjectForFiltering } from './markupCalculator.helpers';

export const selectMarkupsForDenmark = (
  seasonMidlinePriceIndex: ISeasonalPrices,
  markupDatas: IMarkupData[],
  productMarketCountryCode: string,
): Array<IMarketTypes<IMarkupData>> => {
  const markupResults = Object.keys(seasonMidlinePriceIndex).map((seasonName: string) => {
    const seasonMidlinePrice = seasonMidlinePriceIndex[seasonName];

    // Find the markups matching the season
    const seasonMarkups = markupDatas.filter((markupData: IMarkupData) => {
      if (markupData.seasonName === seasonName) {
        return markupData;
      }
    });

    if (seasonMarkups.length === 0) {
      throw new Error(`[markupCalculator] Could not find markups for season name ${seasonName}`);
    }

    const markupForProductMarket = seasonMarkups.find(
      (markup) => markup.salesMarketCountryCode === productMarketCountryCode,
    );

    const markupForSalesMarket = seasonMarkups.find(
      (markup) => markup.salesMarketCountryCode === MARKUP_COUNTRY_FALLBACK.DANISH_SALES_MARKET,
    );

    if (!markupForProductMarket || !markupForSalesMarket) {
      throw new Error(
        `[markupCalculator] Could not find markup in range of midline price ${seasonMidlinePrice}`,
      );
    }

    return {
      productMarket: markupForProductMarket,
      salesMarket: markupForSalesMarket,
    };
  });
  return markupResults;
};

export const getMarkupsForDenmark = (
  markupData: IMarkupData[],
  areaCode: string,
  houseType: number,
  secondYearRevenue: IRevenueResult,
): IMarkupData[] => {
  const revenueMidlineValue = (secondYearRevenue.lowest + secondYearRevenue.highest) / 2;
  const filteredMarkups = markupData
    .filter((markup: IMarkupData) => markup.areaCodeName === areaCode)
    .filter((markup: IMarkupData) => markup.houseType === houseType)
    .filter(
      (markup: IMarkupData) =>
        markup.rangeLow <= revenueMidlineValue && markup.rangeHigh >= revenueMidlineValue,
    );

  if (filteredMarkups.length === 0) {
    throw new Error(
      `[markupCalculator] No Markups found with these filters: Area code - ${areaCode}, House type - ${houseType} and midline revenue ${revenueMidlineValue}`,
    );
  }

  return filteredMarkups;
};

export const getHouseType = (quoteFeatures: IBrandFeatures): number => {
  const { whirlpool, pooltype, sauna } = quoteFeatures;

  if (
    [POOL_TYPES.INDOOR, POOL_TYPES.COUNTERCURRENT, POOL_TYPES.WATERPARK_POOL_ACCESS].includes(
      pooltype,
    )
  ) {
    return HOUSE_TYPES.GROUP_100;
  }

  if (whirlpool !== WHIRLPOOL_TYPES.NONE) {
    return HOUSE_TYPES.GROUP_200;
  }

  if ([SAUNA_TYPES.SAUNA, SAUNA_TYPES.SHARED, SAUNA_TYPES.TANNING_BED].includes(sauna)) {
    return HOUSE_TYPES.GROUP_300;
  }

  return HOUSE_TYPES.GROUP_400;
};

export const getMarkupCascadesForDenmark = async (
  seasonMidlinePriceIndex: ISeasonalPrices,
  quoteFeatures: IBrandFeatures,
  revenueResults: IRevenueResult[],
  areaCode: string | null,
): Promise<Array<IMarketTypes<IMarkupData>>> => {
  const markupData = await appDatabase.markupData.toArray();

  const houseType = getHouseType(quoteFeatures);
  // eslint-disable-next-lineno-console
  console.log('House type: ', houseType);

  if (!areaCode) {
    throw new Error(`[markupCalculator] No area code saved for this quote`);
  }
  const areaCodeObject = await appDatabase.areaCode.get(areaCode);
  if (!areaCodeObject) {
    throw new Error(`[markupCalculator] No area code found with this uuid: ${areaCode}`);
  }
  const revenueForFilter = getRevenueObjectForFiltering(revenueResults);
  const markupCascadePriceRanges = getMarkupsForDenmark(
    markupData,
    areaCodeObject.name,
    houseType,
    revenueForFilter,
  );
  const markupCascades = selectMarkupsForDenmark(
    seasonMidlinePriceIndex,
    markupCascadePriceRanges,
    COUNTRY_CODES.DENMARK,
  );

  // eslint-disable-next-lineno-console
  console.log('markupCascades', markupCascades);

  return markupCascades;
};
