import { ICurrency } from '../appDatabase';

type Nullable<T> = T | null;

export const genericCurrencyConvert =
  (fromCurrency: Nullable<ICurrency>, toCurrency: Nullable<ICurrency>) =>
  (amountToConvert: number): number => {
    if (fromCurrency && toCurrency) {
      return (
        (toCurrency.conversionRateToDefault / fromCurrency.conversionRateToDefault) *
        amountToConvert
      );
    }
    return amountToConvert;
  };
