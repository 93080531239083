import { INewProperty, IUpstreamProperty } from 'services/appDatabase';
import BaseEngine from './base';
import {
  IPricingResult,
  IShortBreakEngine,
  IShortBreakResult,
  IStayTypeValue,
  IWeek,
} from './types';

export interface IShortBreakWeekRatio extends IWeek {
  midweekRatio: number;
  weekendRatio: number;
}

export interface IShortBreakWeek extends IShortBreakWeekRatio {
  midweekPrice: number;
  weekendPrice: number;
}

class ShortBreakEngine extends BaseEngine implements IShortBreakEngine {
  async computeShortBreak(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    pricingResults: IPricingResult[],
    adjustedPriceDistribution?: IStayTypeValue[] | undefined,
  ): Promise<IShortBreakResult> {
    const pricingRegionCode = await this.getPricingRegionCodeForCottages(newProperty);
    const shortBreakArrivalDistribution = await this.getShortBreakArrivalDistribution(
      pricingRegionCode,
      newProperty.bedrooms,
    );
    const [yearOne, yearTwo] = this.getYears(newProperty);
    const priceDistribution = adjustedPriceDistribution || (await this.getPriceDistribution());

    const [shortBreakWeeksYearOne, shortBreakWeeksYearTwo] = await Promise.all([
      this.getWeeksBreakdownForYear(
        pricingResults,
        benchmarkProperties,
        priceDistribution,
        yearOne,
      ),
      this.getWeeksBreakdownForYear(
        pricingResults,
        benchmarkProperties,
        priceDistribution,
        yearTwo,
      ),
    ]);

    const shortBreakResults: IShortBreakResult = {
      arrivalDistribution: shortBreakArrivalDistribution,
      weeks: shortBreakWeeksYearOne.concat(shortBreakWeeksYearTwo),
      priceDistribution,
    };

    // eslint-disable-next-lineno-console
    console.log('ShortBreak results:', shortBreakResults);

    return shortBreakResults;
  }
}

export default ShortBreakEngine;
