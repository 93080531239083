import _sortedUniq from 'lodash/sortedUniq';
import {
  Currencies,
  ICommissionRate,
  IFeatureValue,
  INewProperty,
  IPriceCoefficient,
  IUpstreamProperty,
  IWeekMapping,
} from 'services/appDatabase';
import { IPricingEngine, IPricingResult, ISeasonalPrices } from 'services/engine/types';
import { calculateGuestPrices } from 'services/markup/guestPriceCalculator';
import { calculateOwnerPrice } from 'services/markup/ownerPriceCalculator';
import BaseEngine from '../base';
import { INTERCEPT } from '../constants';
import { PricingEngineException } from '../exceptions';
import { IWeekPricingData, IYearSummary } from '../types';
import { selectWeekMappingsForYearAndCalendar, sumOnField } from '../utils';

interface IFeatureValueSeason extends IFeatureValue {
  season: string;
}

export interface IWeekData {
  weekNumber: number;
  season: string;
  previousWeek: number;
  previousYear: number;
  year: number;
}

class NovasolPricingEngine extends BaseEngine implements IPricingEngine {
  public async computePricing(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    currencies: Currencies,
  ): Promise<IPricingResult[]> {
    const seasonAPrice = await this.computeSeasonAPrice(newProperty);

    const pricingRegion = await this.getPricingRegion(newProperty);
    let year1MarketRate = 0;
    let year2MarketRate = 0;
    if (pricingRegion) {
      year1MarketRate = pricingRegion.year1MarketRate;
      year2MarketRate = pricingRegion.year2MarketRate;
    }
    const yearOne = newProperty.firstAvailable.year;
    const yearTwo = yearOne + 1;

    const yearOneCascadeUUID = await this.getDBCascadeUUIDWithSameNameAs(
      newProperty.cascadeType,
      newProperty.calendar,
      yearOne,
    );
    const yearOneCascadePrices = await this.computeCascadePrices(
      seasonAPrice,
      yearOneCascadeUUID,
      year1MarketRate,
    );
    // eslint-disable-next-line
    console.log('Year One Cascade Prices', yearOneCascadePrices);

    const yearTwoCascadeUUID = await this.getDBCascadeUUIDWithSameNameAs(
      newProperty.cascadeType,
      newProperty.calendar,
      yearTwo,
    );
    const yearTwoCascadePrices = await this.computeCascadePrices(
      seasonAPrice,
      yearTwoCascadeUUID,
      year2MarketRate,
    );
    // eslint-disable-next-line
    console.log('Year Two Cascade Prices', yearTwoCascadePrices);

    const calendarName = await this.db.calendar.where('uuid').equals(newProperty.calendar).first();

    if (!calendarName) {
      throw new PricingEngineException(
        `No calendar found in database for uuid: ${newProperty.calendar}`,
      );
    }

    const yearOneWeekSeasonMapping = await this.buildYearData(yearOne, calendarName.name);
    const yearOneData = this.computeYearData(yearOneWeekSeasonMapping, yearOneCascadePrices);

    const yearTwoWeekSeasonMapping = await this.buildYearData(yearTwo, calendarName.name);
    const yearTwoData = this.computeYearData(yearTwoWeekSeasonMapping, yearTwoCascadePrices);

    return [
      {
        year: yearOne,
        yearData: yearOneData,
      },
      {
        year: yearTwo,
        yearData: yearTwoData,
      },
    ];
  }

  public async computePricingWithGuestCoeffs(
    newProperty: INewProperty,
    _: IUpstreamProperty[],
    { ownerCurrency, guestCurrency }: Currencies,
  ): Promise<IPricingResult[]> {
    const seasonAGuestPriceInDefaultCurrency = await this.computeSeasonAGuestPrice(newProperty);

    const seasonAGuestPriceInOwnerCurrency =
      seasonAGuestPriceInDefaultCurrency * ownerCurrency.conversionRateToDefault;

    const commission = await this.getCommission();
    const seasonAOwnerPriceInOwnerCurrency = await calculateOwnerPrice(
      newProperty,
      ownerCurrency,
      guestCurrency,
      seasonAGuestPriceInOwnerCurrency,
      commission,
    );

    const seasonAOwnerPriceInDefaultCurrency =
      seasonAOwnerPriceInOwnerCurrency / ownerCurrency.conversionRateToDefault;

    return await this.seasonAToYearlyPricesN2S(newProperty, seasonAOwnerPriceInDefaultCurrency);
  }

  public async computePricingN2S(newProperty: INewProperty): Promise<IPricingResult[]> {
    const seasonAOwnerPriceInDefaultCurrency = await this.computeSeasonAPrice(newProperty);
    return await this.seasonAToYearlyPricesN2S(newProperty, seasonAOwnerPriceInDefaultCurrency);
  }

  private async seasonAToYearlyPricesN2S(newProperty: INewProperty, seasonAPrice: number) {
    const pricingRegion = await this.getPricingRegion(newProperty);

    let year1MarketRate = 0;
    let year2MarketRate = 0;
    if (pricingRegion) {
      year1MarketRate = pricingRegion.year1MarketRate;
      year2MarketRate = pricingRegion.year2MarketRate;
    }
    const yearOne = newProperty.firstAvailable.year;
    const yearTwo = yearOne + 1;

    const commission = await this.getCommission();

    const yearOneCascadeUUID = await this.getDBCascadeUUIDWithSameNameAs(
      newProperty.cascadeType,
      newProperty.calendar,
      yearOne,
    );
    const yearOneCascadePrices = await this.computeCascadePrices(
      seasonAPrice,
      yearOneCascadeUUID,
      year1MarketRate,
    );
    // eslint-disable-next-line
    console.log('Year One Cascade Prices', yearOneCascadePrices);

    const yearTwoCascadeUUID = await this.getDBCascadeUUIDWithSameNameAs(
      newProperty.cascadeType,
      newProperty.calendar,
      yearTwo,
    );
    const yearTwoCascadePrices = await this.computeCascadePrices(
      seasonAPrice,
      yearTwoCascadeUUID,
      year2MarketRate,
    );

    // eslint-disable-next-line
    console.log('Year Two Cascade Prices', yearTwoCascadePrices);

    const calendarName = await this.db.calendar.where('uuid').equals(newProperty.calendar).first();

    if (!calendarName) {
      throw new PricingEngineException(
        `No calendar found in database for uuid: ${newProperty.calendar}`,
      );
    }

    const yearOneWeekSeasonMapping = await this.buildYearData(yearOne, calendarName.name);
    const yearOneData = this.computeYearDataN2S(
      yearOneWeekSeasonMapping,
      yearOneCascadePrices,
      await calculateGuestPrices(newProperty, yearOneCascadePrices, commission),
    );

    const yearTwoWeekSeasonMapping = await this.buildYearData(yearTwo, calendarName.name);
    const yearTwoData = this.computeYearDataN2S(
      yearTwoWeekSeasonMapping,
      yearTwoCascadePrices,
      await calculateGuestPrices(newProperty, yearTwoCascadePrices, commission),
    );

    return [
      {
        year: yearOne,
        yearData: yearOneData,
      },
      {
        year: yearTwo,
        yearData: yearTwoData,
      },
    ];
  }

  private async getCommission() {
    const commissionRate: ICommissionRate | undefined = await this.db.commissionRate
      .toCollection()
      .first();

    const DEFAULT_COMMISSION = 0.4;
    const commission = commissionRate?.value ?? DEFAULT_COMMISSION;
    return commission;
  }

  protected computeYearData(
    weekSeasonMapping: IWeekData[],
    cascade: { [seasonUUID: string]: number },
  ) {
    return weekSeasonMapping.map((week) => {
      const price = cascade[week.season];
      if (!price) {
        throw new PricingEngineException(`No price found for season: ${week.season}`);
      }
      return { ...week, price, open: true };
    });
  }

  protected computeYearDataN2S(
    weekSeasonMapping: IWeekData[],
    cascade: { [seasonUUID: string]: number },
    salesPriceCascade: { [seasonUUID: string]: number },
  ) {
    return weekSeasonMapping.map((week) => {
      const price = cascade[week.season];
      const salesPrice = salesPriceCascade[week.season];
      if (!price) {
        throw new PricingEngineException(`No price found for season: ${week.season}`);
      }
      return { ...week, price, salesPrice, open: true };
    });
  }

  public async computeCascadePrices(
    seasonAPrice: number,
    cascadeTypeUUID: string,
    marketRate = 0,
  ): Promise<ISeasonalPrices> {
    const cascades = await this.getDBCascadeDataForUUID(cascadeTypeUUID);

    // eslint-disable-next-line
    console.log('Cascades', cascades);

    if (cascades.length === 0) {
      throw new PricingEngineException(
        `No cascades found in database for uuid: ${cascadeTypeUUID}`,
      );
    }

    return cascades.reduce((seasonPriceMapping, cascade) => {
      let price = cascade.value * seasonAPrice;
      if (marketRate) {
        price += price * (marketRate / 100);
      }
      return {
        ...seasonPriceMapping,
        [cascade.season]: price,
      };
    }, {});
  }

  public async computeSeasonAPrice(newProperty: INewProperty): Promise<number> {
    const pricingRegion = await this.getPricingRegion(newProperty);
    const pricingRegionCode = pricingRegion.code;

    // eslint-disable-next-line
    console.log('[NovasolPricingEngine] -> pricingRegion', pricingRegion);

    const priceCoefficientsForRegion = await this.getAllRegionalPriceCoefficients(
      pricingRegionCode,
    );
    const priceCoefficients = this.mapCoefficientToFeatureValue(priceCoefficientsForRegion);

    const basePricingForRegion = priceCoefficients.find(
      (priceCoefficient) => priceCoefficient.featureName === INTERCEPT,
    );
    if (!basePricingForRegion) {
      throw new PricingEngineException(
        '[SeasonAPrice] Could not find an intercept value in the price coeff table',
      );
    }

    const newPropertyNakedPrice = basePricingForRegion.value;

    // eslint-disable-next-line
    console.log('TCL: NovasolPricingEngine -> newPropertyNakedPrice', newPropertyNakedPrice);

    const newPropertyWeightedCoefficients = this.mapToFeatureValues(newProperty, priceCoefficients);
    // eslint-disable-next-line
    console.log(
      'NovasolPricingEngine -> newPropertyWeightedCoefficients',
      newPropertyWeightedCoefficients,
    );
    const totalNewPropertyWeightedCoefficients = sumOnField(
      newPropertyWeightedCoefficients,
      'value',
    );

    const newPropertyFinalPrice = newPropertyNakedPrice + totalNewPropertyWeightedCoefficients;
    // eslint-disable-next-line
    console.log('NovasolPricingEngine -> newPropertyFinalPrice', newPropertyFinalPrice);

    return newPropertyFinalPrice;
  }

  protected async getAllRegionalPriceCoefficients(
    pricingRegion: string,
  ): Promise<IPriceCoefficient[]> {
    const coefficients = await this.db.priceCoefficient
      .where('pricingRegionCode')
      .equals(pricingRegion);
    return coefficients.toArray();
  }

  public async computeSeasonAGuestPrice(newProperty: INewProperty): Promise<number> {
    const pricingRegion = await this.getPricingRegion(newProperty);
    const pricingRegionCode = pricingRegion.code;

    // eslint-disable-next-line
    console.log('[NovasolPricingEngine] -> pricingRegion', pricingRegion);

    const priceCoefficientsForRegion = await this.getAllRegionalGuestPriceCoefficients(
      pricingRegionCode,
    );
    const guestPriceCoefficients = this.mapCoefficientToFeatureValue(priceCoefficientsForRegion);

    const basePricingForRegion = guestPriceCoefficients.find(
      (priceCoefficient) => priceCoefficient.featureName === INTERCEPT,
    );
    if (!basePricingForRegion) {
      throw new PricingEngineException(
        '[SeasonAPrice] Could not find an intercept value in the price coeff table',
      );
    }

    const newPropertyNakedPrice = basePricingForRegion.value;

    // eslint-disable-next-line
    console.log('TCL: NovasolPricingEngine -> newPropertyNakedPrice', newPropertyNakedPrice);

    const newPropertyWeightedCoefficients = this.mapToFeatureValues(
      newProperty,
      guestPriceCoefficients,
    );
    // eslint-disable-next-line
    console.log(
      'NovasolPricingEngine -> newPropertyWeightedCoefficients',
      newPropertyWeightedCoefficients,
    );
    const totalNewPropertyWeightedCoefficients = sumOnField(
      newPropertyWeightedCoefficients,
      'value',
    );

    const newPropertyFinalPrice = newPropertyNakedPrice + totalNewPropertyWeightedCoefficients;
    // eslint-disable-next-line
    console.log('NovasolPricingEngine -> newPropertyFinalPrice', newPropertyFinalPrice);

    return newPropertyFinalPrice;
  }

  protected async getAllRegionalGuestPriceCoefficients(
    pricingRegion: string,
  ): Promise<IPriceCoefficient[]> {
    const coefficients = await this.db.guestPriceCoefficient
      .where('pricingRegionCode')
      .equals(pricingRegion);
    return coefficients.toArray();
  }

  protected mapCoefficientToFeatureValue(
    priceCoefficientsForRegion: IPriceCoefficient[],
  ): IFeatureValueSeason[] {
    return priceCoefficientsForRegion.map((priceCoefficient) => ({
      season: priceCoefficient.season,
      featureName: priceCoefficient.featureName,
      value: priceCoefficient.coefficient,
    }));
  }

  protected buildYearData = async (year: number, calendar: string): Promise<IWeekData[]> => {
    const weekMappings = await selectWeekMappingsForYearAndCalendar(year, calendar);
    const weekNumbers = _sortedUniq(weekMappings.map((weekMapping) => weekMapping.currentWeek));

    return weekNumbers.map((weekNumber: number) => {
      const weekMapping = weekMappings.find((wm: IWeekMapping) => wm.currentWeek === weekNumber);
      if (!weekMapping) {
        throw new PricingEngineException(
          `Cannot find a week mapping for weekNumber: ${weekNumber} year: ${year}`,
        );
      }
      return {
        weekNumber,
        year,
        previousWeek: weekMapping.previousWeek,
        previousYear: weekMapping.previousYear,
        season: weekMapping.season,
      };
    });
  };
}

export default NovasolPricingEngine;

export const getYearSummary = (yearData: IWeekPricingData[]): IYearSummary =>
  yearData.reduce((summary: IYearSummary, week: IWeekPricingData) => {
    const previous = summary.get(week.season);
    summary.set(week.season, {
      highest: previous ? Math.max(previous.highest, week.price) : week.price,
      lowest: previous ? Math.min(previous.lowest, week.price) : week.price,
    });
    return summary;
  }, new Map());

export type PriceSummary = { price: number; salesPrice: number };
export type ICompleteYearSummary = Map<string, PriceSummary>;

export const getCompleteYearSummary = (yearData: IWeekPricingData[]): ICompleteYearSummary =>
  yearData.reduce((summary: ICompleteYearSummary, week: IWeekPricingData) => {
    summary.set(week.season, {
      price: week.price,
      salesPrice: week.salesPrice ?? 0,
    });
    return summary;
  }, new Map());
