export const round = (x: number, dec: number): number => {
  const mult = Math.pow(10, dec);
  return Math.round(x * mult) / mult;
};

export const formatPercentage = (percentage: number): string =>
  percentage < 0.01 ? '<1%' : `${Math.round(percentage * 100)}%`;

export const applyFloorCap = (price: number, floorPrice: number | null) =>
  floorPrice === null ? price : Math.max(price, floorPrice);

export const decimalAsPercentage = (decimal: number) => decimal * 100;

export const capBetween = (value: number, min = 0, max = 1) => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};

export const getStepSize = (lowest: number, highest: number, steps: number) => {
  return (
    Math.round((highest - lowest) / (steps * 1000)) * 1000 ||
    Math.round((highest - lowest) / (steps * 100)) * 100 ||
    100
  );
};
