import _groupBy from 'lodash/groupBy';
import _toPairs from 'lodash/toPairs';
import {
  IBookingProbability,
  INewProperty,
  IShortBreakArrivalDistribution,
  IUpstreamProperty,
} from 'services/appDatabase';
import BaseEngine from './base';
import {
  IAveragePriceChangeEngine,
  IAveragePriceChangeResult,
  IPricingResult,
  IShortBreakResult,
  IWeek,
  IWeekStayTypeBreakdown,
} from './types';

interface IWeeklyAverage extends IWeek {
  average: number;
}

class AveragePriceChangeEngine extends BaseEngine implements IAveragePriceChangeEngine {
  public async computeAveragePriceChange(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    ownerShortBreak: IShortBreakResult,
    ownerPricing: IPricingResult[],
    bdmPricing: IPricingResult[],
  ): Promise<IAveragePriceChangeResult[]> {
    const aggregatedShortBreabArrivalDistribution: IShortBreakArrivalDistribution[] =
      await this.getAggregatedShortBreabArrivalDistribution(newProperty);
    const pricingRegionCode = await this.getPricingRegionCodeForCottages(newProperty);
    // eslint-disable-next-lineno-console
    console.log('Using property region code: ' + pricingRegionCode);
    const bookingProbabilities = await this.selectBookingProbabilities(pricingRegionCode);
    const priceDistribution = await this.getPriceDistribution();
    const [yearOne, yearTwo, yearThree] = this.getYears(newProperty);

    // Get BDM breakdown
    const [bdmShortBreakWeeksYearOne, bdmShortBreakWeeksYearTwo] = await Promise.all([
      this.getWeeksBreakdownForYear(bdmPricing, benchmarkProperties, priceDistribution, yearOne),
      this.getWeeksBreakdownForYear(bdmPricing, benchmarkProperties, priceDistribution, yearTwo),
    ]);
    const bdmShortBreakWeeks = bdmShortBreakWeeksYearOne.concat(bdmShortBreakWeeksYearTwo);

    // Compute weekly averages
    const ownerWeeklyAverages = this.getWeeklyAverages(
      aggregatedShortBreabArrivalDistribution,
      ownerShortBreak.weeks,
    );
    const bdmWeeklyAverages = this.getWeeklyAverages(
      aggregatedShortBreabArrivalDistribution,
      bdmShortBreakWeeks,
    );

    // Get Yearly averages
    const ownerYearlyAverages = this.getYearlyAverage(ownerWeeklyAverages, bookingProbabilities);
    const bdmYearlyAverages = this.getYearlyAverage(bdmWeeklyAverages, bookingProbabilities);

    const averagePriceChanges = ownerYearlyAverages.map((ownerYearlyAverage) => {
      const bdmYearlyAverage = bdmYearlyAverages.find(
        (bdmA) => bdmA.year === ownerYearlyAverage.year,
      );
      if (!bdmYearlyAverage) {
        throw Error(`Could not find a bdm yearly average for year ${ownerYearlyAverage.year}`);
      }
      return {
        year: ownerYearlyAverage.year,
        averagePriceChange: ownerYearlyAverage.average / bdmYearlyAverage.average - 1,
      };
    });

    const averagePriceChangeYearTwo = averagePriceChanges.find((apc) => apc.year === yearTwo);
    if (!averagePriceChangeYearTwo) {
      throw Error(`Could not find an average price change for year ${yearTwo}`);
    }
    averagePriceChanges.push({
      year: yearThree,
      averagePriceChange: averagePriceChangeYearTwo.averagePriceChange,
    });

    // eslint-disable
    console.log(
      'Aggregated ShortBreab Arrival Distribution',
      aggregatedShortBreabArrivalDistribution,
    );
    console.log('Owner weekly averages', ownerWeeklyAverages);
    console.log('Owner yearly averages', ownerYearlyAverages);
    console.log('BDM weekly averages', bdmWeeklyAverages);
    console.log('BDM yearly averages', bdmYearlyAverages);
    console.log('Average price change', averagePriceChanges);
    // eslint-enable

    return averagePriceChanges;
  }

  protected async getAggregatedShortBreabArrivalDistribution(
    newProperty: INewProperty,
  ): Promise<IShortBreakArrivalDistribution[]> {
    const pricingRegionCode = await this.getPricingRegionCodeForCottages(newProperty);
    // eslint-disable-next-lineno-console
    console.log('Using property region code: ' + pricingRegionCode);

    const arrivalDistribution: IShortBreakArrivalDistribution[] =
      await this.getShortBreakArrivalDistribution(pricingRegionCode, newProperty.bedrooms);

    const aggregatedArrivalDistribution: IShortBreakArrivalDistribution[] =
      arrivalDistribution.filter((shortbreak) => shortbreak.type.nights < 5);

    aggregatedArrivalDistribution.push(
      this.sumArrivalDistributionForNights(arrivalDistribution, 5),
    );
    aggregatedArrivalDistribution.push(
      this.sumArrivalDistributionForNights(arrivalDistribution, 6),
    );
    aggregatedArrivalDistribution.push(
      this.sumArrivalDistributionForNights(arrivalDistribution, 7),
    );

    return aggregatedArrivalDistribution;
  }

  protected getWeeklyAverages(
    arrivalDistribution: IShortBreakArrivalDistribution[],
    shortBreakResultWeeks: IWeekStayTypeBreakdown[],
  ): IWeeklyAverage[] {
    const distributionSum = arrivalDistribution.reduce((sum, { factor }) => sum + factor, 0);

    const weeklyAverages = shortBreakResultWeeks.map(({ prices, weekNumber, year }) => {
      const sum = prices
        .map((p) => {
          const arrivalFactor = arrivalDistribution.find(
            ({ type: { nights, type } }) => nights === p.nights && type === p.type,
          );
          if (!arrivalFactor) {
            throw Error(`Could not find an arrival distribution factor for ${p.type} ${p.nights}.`);
          }
          return p.value * arrivalFactor.factor;
        })
        .reduce((total, item) => total + item, 0);

      return {
        weekNumber,
        year,
        average: sum / distributionSum,
      };
    });

    return weeklyAverages;
  }

  protected getYearlyAverage(
    weeklyAverages: IWeeklyAverage[],
    bookingProbabilities: IBookingProbability[],
  ) {
    const bookingProbabilitiesSum = bookingProbabilities.reduce(
      (acc, { bookingProbability }) => acc + bookingProbability,
      0,
    );

    return _toPairs(_groupBy(weeklyAverages, ({ year }) => year)).map(
      ([year, filteredAverages]) => {
        const sum = filteredAverages
          .map((weeklyAverage) => {
            const bookingProbability = bookingProbabilities.find(
              ({ weekNumber }) => weekNumber === weeklyAverage.weekNumber,
            );
            if (!bookingProbability) {
              throw Error(
                `Could not find an booking probability for week ${weeklyAverage.weekNumber}.`,
              );
            }
            return weeklyAverage.average * bookingProbability.bookingProbability;
          })
          .reduce((total, item) => total + item, 0);

        return {
          year: parseInt(year, 10),
          average: sum / bookingProbabilitiesSum,
        };
      },
    );
  }

  private sumArrivalDistributionForNights = (
    arrivalDistribution: IShortBreakArrivalDistribution[],
    nights: number,
  ): IShortBreakArrivalDistribution => {
    return arrivalDistribution
      .filter((shortbreakDistribution) => shortbreakDistribution.type.nights === nights)
      .reduce((acc, sb) => ({ ...acc, factor: acc.factor + sb.factor }), {
        pricingRegionCode: arrivalDistribution[0].pricingRegionCode,
        bedrooms: arrivalDistribution[0].bedrooms,
        factor: 0,
        type: {
          nights,
          type: 'fullweek',
        },
      });
  };
}

export default AveragePriceChangeEngine;
