import _groupBy from 'lodash/groupBy';
import _range from 'lodash/range';
import { DateTime } from 'luxon';
import { ISeason } from './appDatabase';

const NOVASOL_START_DAY = 6;

interface IWeek {
  weekNumber: number;
  date: DateTime;
  month: {
    name: string;
    index: number;
  };
  year: number;
  color: string;
  seasonName: string;
}

export const getDateForWeekDays = (
  year: number,
  options?: { numberOfWeeks?: number; format?: string },
) => {
  const numberOfWeeks = options ? options.numberOfWeeks : undefined;
  const format = options ? options.format : undefined;
  return _range(1, (numberOfWeeks || 52) + 1).map((i) => getDateForWeekDay(year, i, format));
};

export const getDateForWeekDay = (year: number, weekNumber: number, format?: string) => {
  const weekday = NOVASOL_START_DAY;
  return DateTime.fromObject({ weekYear: year, weekNumber, weekday });
};

export const getWeekDate = (year: number, weekNumber: number) => {
  if (weekNumber < 1 || weekNumber > 54) {
    throw new Error('Weeknumber should be between 1 and 54');
  }
  return getDateForWeekDay(year, weekNumber);
};

export const isWeekInRange = (weekToCheck: number, openDate: DateTime, closeDate: DateTime) => {
  // if Luxon fails to parse the date (if the user doesn't select open/close dates), we still recieve a DateTime object, but weeknumber is NaN
  if (isNaN(openDate.weekNumber) && isNaN(closeDate.weekNumber)) {
    return true;
  }
  if (!isNaN(openDate.weekNumber) && isNaN(closeDate.weekNumber)) {
    return openDate.weekNumber <= weekToCheck;
  }
  if (isNaN(openDate.weekNumber) && !isNaN(closeDate.weekNumber)) {
    return weekToCheck <= closeDate.weekNumber;
  }
  return openDate.weekNumber <= weekToCheck && weekToCheck <= closeDate.weekNumber;
};

export const isWeekInRangeForBlockedWeeks = (
  weekToCheck: number,
  openDate: DateTime,
  closeDate: DateTime,
) => {
  // if Luxon fails to parse the date (if the user doesn't select open/close dates), we still recieve a DateTime object, but weeknumber is NaN
  if (isNaN(openDate.weekNumber) && isNaN(closeDate.weekNumber)) {
    return true;
  }

  const year = openDate.year || closeDate.year;
  const weekDate = DateTime.fromObject({
    weekYear: year,
    weekNumber: weekToCheck,
    weekday: NOVASOL_START_DAY - 1,
  });

  if (!isNaN(openDate.weekNumber) && isNaN(closeDate.weekNumber)) {
    return openDate <= weekDate;
  }
  if (isNaN(openDate.weekNumber) && !isNaN(closeDate.weekNumber)) {
    return weekDate.diff(closeDate).as('days') <= 6;
  }
  return openDate <= weekDate && weekDate.diff(closeDate).as('days') <= 6;
};

export const getYearOne = (yearArray: Array<{ year: number }>) =>
  Math.min(...yearArray.map((e) => e.year));

export const getMonth = (year: number, weekNumber: number) => {
  const weekday = NOVASOL_START_DAY;

  if (weekNumber === 53) {
    weekNumber = 1;
    year += 1;
  }

  const month = DateTime.fromObject({ weekYear: year, weekNumber, weekday });

  return {
    name: month.toLocaleString({
      month: 'long',
      locale: localStorage.getItem('locale') || 'en-GB',
    }),
    index: month.month,
  };
};

export const getCalendarWeeks = (
  pricingResultYearOne: Array<{ season: string; weekNumber: number; year: number }>,
  seasons: ISeason[],
) => {
  const weeks = pricingResultYearOne
    .map((week) => {
      const season = seasons.find((s) => s.uuid === week.season);
      if (!season) {
        throw Error(`No season found with this uuid: ${week.season}`);
      }
      const { weekNumber, year } = week;
      const month = getMonth(year, weekNumber);
      const isInNextYear = month.index === 1 && weekNumber > 10;

      const newWeekNumber = isInNextYear ? 1 : weekNumber + 1;

      const newWeek = pricingResultYearOne.find((w) => {
        if (newWeekNumber === 2) {
          return w.weekNumber === 3;
        }
        return w.weekNumber === newWeekNumber;
      });
      if (!newWeek) {
        return null;
      }

      const newSeason = seasons.find((s) => s.uuid === newWeek.season);
      if (!newSeason) {
        return null;
      }

      return {
        weekNumber: newWeekNumber, // Trying to make the "real calendar" and "Novasol calendar" match
        date: getWeekDate(year, weekNumber),
        month,
        year: isInNextYear ? year + 1 : year,
        color: newSeason.color,
        seasonName: newSeason.name,
      };
    })
    .filter((week): week is IWeek => week !== null && week.month.name !== 'Invalid DateTime');

  // Sometimes week 3 is missing so create one based on week 4
  const week3 = weeks.find((week) => week.weekNumber === 3);
  const week4Index = weeks.findIndex((week) => week.weekNumber === 4);
  if (!week3 && week4Index > -1) {
    weeks.splice(week4Index, 0, {
      ...weeks[week4Index],
      date: getWeekDate(weeks[week4Index].year, 2),
      weekNumber: 3,
    });
  }
  return weeks.filter((w) => w !== null);
};

export const noNewYearWeeksInDecember = (
  month: Array<{
    weekNumber: number;
    date: DateTime;
    month: {
      name: string;
      index: number;
    };
    color: string;
    seasonName: string;
  }>,
) => month[month.length - 1].month.index === 12;

export const getCalendarDataForYearOne = (
  pricingResultYearOne: Array<{ season: string; weekNumber: number; year: number }>,
  pricingResultYearTwo: Array<{ season: string; weekNumber: number; year: number }>,
  seasons: ISeason[],
) => {
  const yearOne = pricingResultYearOne[0].year;

  const validWeeks = getCalendarWeeks(pricingResultYearOne, seasons)
    .map((week) => {
      if (!week) {
        return null;
      }
      return { ...week, weekNumber: week.weekNumber };
    }) // Trying to make the "real calendar" and "Novasol calendar" match
    .filter(
      (week): week is IWeek =>
        (week !== null && week.weekNumber > 2) || (week !== null && week.year > yearOne),
    ); // Don't show the second as we want to start on the second saturday of the year

  const weeksInYearOne = validWeeks.filter((week) => week.year === yearOne);
  const weeksInYearTwo = validWeeks.filter((week) => week.year === yearOne + 1);

  const months = _groupBy(
    weeksInYearOne,
    (week: { month: { name: string; index: number } }) => week.month.index,
  );

  // Move any weeks in january from year N+1 into december of year N
  weeksInYearTwo.map((week) => months[12].push(week));

  // We may need to use next years data to find the first week of year N+1
  if (noNewYearWeeksInDecember(months[12])) {
    const newWeek = pricingResultYearTwo.find((week) => week.weekNumber === 1);
    const newWeekSeason = seasons.find((s) => s.uuid === newWeek!.season);

    if (!newWeek || !newWeekSeason) {
      throw new Error(`problem`);
    }
    months[12].push({
      weekNumber: months[12][months[12].length - 1].weekNumber === 1 ? 2 : 1,
      date: getWeekDate(newWeek.year, newWeek.weekNumber),
      month: getMonth(newWeek.year, newWeek.weekNumber),
      color: newWeekSeason.color,
      seasonName: newWeekSeason.name,
      year: yearOne + 1,
    });
  }

  return months;
};

export const getCalendarDataForYearTwo = (
  pricingResultYearOne: Array<{ season: string; weekNumber: number; year: number }>,
  pricingResultYearTwo: Array<{ season: string; weekNumber: number; year: number }>,
  seasons: ISeason[],
) => {
  const yearTwo = pricingResultYearTwo[0].year;
  const yearTwoValidWeeks = getCalendarWeeks(pricingResultYearTwo, seasons)
    .map((week) => ({ ...week, weekNumber: week.weekNumber }))
    .filter((week) => week.weekNumber > 2 || week.year > yearTwo);

  const weeksInYearTwo = yearTwoValidWeeks.filter((week) => week.year === yearTwo);

  const months = _groupBy(
    weeksInYearTwo,
    (week: { month: { name: string; index: number } }) => week.month.index,
  );

  return months;
};
