import numeral from 'numeral';
import { addLocaleData } from 'react-intl';
import daLocale from 'react-intl/locale-data/da';
import deLocale from 'react-intl/locale-data/de';
import enLocale from 'react-intl/locale-data/en';
import esLocale from 'react-intl/locale-data/es';
import frLocale from 'react-intl/locale-data/fr';
import hrLocale from 'react-intl/locale-data/hr';
import itLocale from 'react-intl/locale-data/it';
import nlLocale from 'react-intl/locale-data/nl';
import noLocale from 'react-intl/locale-data/no';
import seLocale from 'react-intl/locale-data/se';
import flattenMessages from 'services/i18n/intl';

import deMessages from 'translations/de.json';
import daMessages from 'translations/dk.json';
import enMessages from 'translations/en.json';
import esMessages from 'translations/es.json';
import frMessages from 'translations/fr.json';
import hrMessages from 'translations/hr.json';
import itMessages from 'translations/it.json';
import nlMessages from 'translations/nl.json';
import noMessages from 'translations/no.json';
import seMessages from 'translations/se.json';

import allRegionsEnglish from 'translations/regions/all_regions_english.json';
import regionsCroatia from 'translations/regions/croatia.json';
import regionsDenmark from 'translations/regions/denmark.json';
import regionsGermany from 'translations/regions/germany.json';
import regionsNetherland from 'translations/regions/netherland.json';
import regionsNorway from 'translations/regions/norway.json';
import regionsSpain from 'translations/regions/spain.json';
import regionsSweden from 'translations/regions/sweden.json';

export const setupTranslations = () => {
  const locales = {
    en: flattenMessages({ ...enMessages, ...allRegionsEnglish }),
    fr: flattenMessages({ ...frMessages, ...allRegionsEnglish }),
    de: flattenMessages({ ...deMessages, ...allRegionsEnglish, ...regionsGermany }),
    da: flattenMessages({ ...daMessages, ...allRegionsEnglish, ...regionsDenmark }),
    es: flattenMessages({ ...esMessages, ...allRegionsEnglish, ...regionsSpain }),
    hr: flattenMessages({ ...hrMessages, ...allRegionsEnglish, ...regionsCroatia }),
    it: flattenMessages({ ...itMessages, ...allRegionsEnglish }),
    nl: flattenMessages({ ...nlMessages, ...allRegionsEnglish, ...regionsNetherland }),
    no: flattenMessages({ ...noMessages, ...allRegionsEnglish, ...regionsNorway }),
    se: flattenMessages({ ...seMessages, ...allRegionsEnglish, ...regionsSweden }),
  };

  addLocaleData([
    ...frLocale,
    ...enLocale,
    ...deLocale,
    ...enLocale,
    ...esLocale,
    ...frLocale,
    ...hrLocale,
    ...itLocale,
    ...nlLocale,
    ...noLocale,
    ...seLocale,
    ...daLocale,
  ]);

  numeral.register('locale', 'eur', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
    ordinal: (n) => `${n}`,
    currency: { symbol: '' },
  });

  // switch between locales
  numeral.locale('eur');

  return locales;
};
