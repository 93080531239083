import { MARKUP_COUNTRY_FALLBACK } from 'constants/countries';
import appDatabase, {
  ICascadeType,
  ICountry,
  ICurrency,
  ICurrencyFee,
  IMarkupData,
  IMarkupHomeInsurance,
  IMarkupMapping,
  IMarkupSeasonalExtras,
  IMarkupValue,
  IPriceMapping,
} from 'services/appDatabase';
import { ISeasonalPrices } from 'services/engine/types';

export interface IMarketTypes<T> {
  productMarket: T;
  salesMarket: T;
}

export const selectMarkupWithinPriceRange = (
  seasonMidlinePriceIndexInOwnerCurrency: ISeasonalPrices,
  markupDatas: IMarkupData[],
  productMarketCountryCode: string,
): Array<IMarketTypes<IMarkupData>> => {
  const markupResults = Object.keys(seasonMidlinePriceIndexInOwnerCurrency).map(
    (seasonName: string) => {
      const seasonAMidlinePriceInOwnerCurrency = seasonMidlinePriceIndexInOwnerCurrency.A;

      if (!seasonAMidlinePriceInOwnerCurrency) {
        throw new Error(`[markupCalculator] Could not find a price for season A`);
      }

      // find the markups matching the season
      const seasonMarkups = markupDatas.filter((markupData: IMarkupData) => {
        if (markupData.seasonName === seasonName) {
          return markupData;
        }
      });

      if (seasonMarkups.length === 0) {
        throw new Error(`[markupCalculator] Could not find markups for season name ${seasonName}`);
      }

      // find markup for the season price
      const matchingRangeMarkup = seasonMarkups.filter(
        (markupData: IMarkupData) =>
          markupData.rangeLow <= seasonAMidlinePriceInOwnerCurrency &&
          markupData.rangeHigh >= Math.floor(seasonAMidlinePriceInOwnerCurrency),
      );

      // Look for the country's markup cascade first and fallback to DEFAULT_FALLBACK_COUNTRY if not found
      let markupForProductMarket = matchingRangeMarkup.find(
        (markup) => markup.salesMarketCountryCode === productMarketCountryCode,
      );
      if (!markupForProductMarket) {
        markupForProductMarket = matchingRangeMarkup.find(
          (markup) => markup.salesMarketCountryCode === MARKUP_COUNTRY_FALLBACK.CASCADE,
        );
      }

      // Look for the euro's markup cascade first and fallback to DEFAULT_FALLBACK_COUNTRY if not found
      let markupForSalesMarket = matchingRangeMarkup.find(
        (markup) => markup.salesMarketCountryCode === MARKUP_COUNTRY_FALLBACK.EURO,
      );
      if (!markupForSalesMarket) {
        markupForSalesMarket = matchingRangeMarkup.find(
          (markup) => markup.salesMarketCountryCode === MARKUP_COUNTRY_FALLBACK.CASCADE,
        );
      }

      if (!markupForProductMarket || !markupForSalesMarket) {
        throw new Error(
          `[markupCalculator] Could not find markup in range of midline price ${seasonAMidlinePriceInOwnerCurrency}`,
        );
      }

      return {
        productMarket: markupForProductMarket,
        salesMarket: markupForSalesMarket,
      };
    },
  );
  return markupResults;
};

export const getPriceMappingForCalendar = async (
  calendarUUID: string,
  priceMappings: IPriceMapping[],
  propertyYear: string,
) => {
  const filteredMappings = priceMappings.filter((mapping: IPriceMapping) => {
    // Match PriceMapping for calendar & year
    const filteringCondition =
      calendarUUID === mapping.calendar && `20${mapping.priceId.slice(2, 4)}` === propertyYear;

    if (filteringCondition) {
      return mapping;
    }
  });
  if (filteredMappings.length === 0) {
    throw new Error(`[markupCalculator] No priceMappings found for calendarUUID:${calendarUUID}`);
  }
  return filteredMappings[0];
};

export const getFilteredMarkupSeasonalExtras = (
  productMarketCountryCode: string,
  priceMapping: IPriceMapping,
  seasonalExtras: IMarkupSeasonalExtras[],
): IMarketTypes<IMarkupSeasonalExtras[]> => {
  const filteredExtras = seasonalExtras.filter((extra: IMarkupSeasonalExtras) => {
    if (extra.priceId === priceMapping.priceId) {
      return extra;
    }
  });

  let extrasProductMarket = filteredExtras.filter(
    (extra) => extra.productMarketCountryCode === productMarketCountryCode,
  );

  if (extrasProductMarket.length === 0) {
    console.warn(
      `No minorDamages / cancellation found for country ${productMarketCountryCode}, falling back to country ${MARKUP_COUNTRY_FALLBACK.SEASONAL_EXTRA}`,
    );
    extrasProductMarket = filteredExtras.filter(
      (extra) => extra.productMarketCountryCode === MARKUP_COUNTRY_FALLBACK.SEASONAL_EXTRA,
    );
  }

  const extrasSalesMarket = filteredExtras.filter(
    (extra) => extra.productMarketCountryCode === MARKUP_COUNTRY_FALLBACK.SEASONAL_EXTRA,
  );

  if (extrasProductMarket.length === 0 || extrasSalesMarket.length === 0) {
    throw new Error(
      `[markupCalculator] No MarkupSeasonalExtra for (${productMarketCountryCode} or ${MARKUP_COUNTRY_FALLBACK.SEASONAL_EXTRA}) and priceMapping.priceId:${priceMapping.priceId}`,
    );
  }

  return {
    productMarket: extrasProductMarket,
    salesMarket: extrasSalesMarket,
  };
};

export const getCurrencyRateOfExchange = (
  productMarketCurrency: ICurrency,
  salesMarketCurrency: ICurrency,
  currencyFees: ICurrencyFee[],
): ICurrencyFee => {
  if (productMarketCurrency.code === salesMarketCurrency.code) {
    return {
      uuid: '',
      productMarketCurrency: productMarketCurrency.code,
      salesMarketCurrency: salesMarketCurrency.code,
      conversion: 1,
    };
  }

  const filteredCurrencyROE = currencyFees.filter((currencyFee: ICurrencyFee) => {
    if (
      currencyFee.productMarketCurrency === productMarketCurrency.code &&
      currencyFee.salesMarketCurrency === salesMarketCurrency.code
    ) {
      return currencyFee;
    }
  });

  if (filteredCurrencyROE.length === 0) {
    throw new Error(
      `[markupCalculator] No CurrencyFee found for productMarketCurrency:${productMarketCurrency.code} salesMarketCurrency:${salesMarketCurrency.code}`,
    );
  }

  return filteredCurrencyROE[0];
};

export const getCascadeType = (
  cascadeTypes: ICascadeType[],
  propertyCascadeType: string,
): ICascadeType => {
  const filteredCascadeTypes = cascadeTypes.find(
    (cascadeType: ICascadeType) => cascadeType.uuid === propertyCascadeType,
  );

  if (!filteredCascadeTypes) {
    throw new Error(`[markupCalculator] No cascades matching ${propertyCascadeType}`);
  }

  return filteredCascadeTypes;
};

export const getFilteredMapping = (
  markupMappings: IMarkupMapping[],
  cascadeName: string,
  countryCode: string,
): IMarkupMapping => {
  const filteredMappings = markupMappings.find((mapping: IMarkupMapping) => {
    if (
      mapping.productMarketCountryCode === countryCode &&
      cascadeName === mapping.cascadeTypeName
    ) {
      return mapping;
    }
  });

  if (!filteredMappings) {
    throw new Error(
      `[markupCalculator] No Markup Mappings matching name:${cascadeName} country.code:${countryCode}`,
    );
  }

  return filteredMappings;
};

export const getFilteredMarkups = (
  markupData: IMarkupData[],
  filteredMapping: IMarkupMapping,
): IMarkupData[] => {
  const filteredMarkups = markupData.filter((markup: IMarkupData) => {
    if (markup.markupId === filteredMapping.markupId) {
      return markup;
    }
  });
  if (filteredMarkups.length === 0) {
    throw new Error(
      `[markupCalculator] No Markups found for MarkupMapping.markupId:${filteredMapping.markupId}`,
    );
  }
  return filteredMarkups;
};

export const getHomeInsuranceValues = (
  productMarketCountryCode: string,
  salesMarketCountryCode: string,
  markupHomeInsurance: IMarkupHomeInsurance[],
): { productMarket: number; salesMarket: number } => {
  let productMarketHomeInsurances = markupHomeInsurance.find(
    (homeInsurance: IMarkupHomeInsurance) =>
      homeInsurance.productMarketCountryCode === productMarketCountryCode &&
      homeInsurance.salesMarketCountryCode === productMarketCountryCode,
  );
  if (!productMarketHomeInsurances) {
    console.warn(
      `No home insurance found for country ${productMarketCountryCode}, falling back to country ${MARKUP_COUNTRY_FALLBACK.INSURANCE}`,
    );
    productMarketHomeInsurances = markupHomeInsurance.find(
      (homeInsurance: IMarkupHomeInsurance) =>
        homeInsurance.productMarketCountryCode === productMarketCountryCode &&
        homeInsurance.salesMarketCountryCode === MARKUP_COUNTRY_FALLBACK.INSURANCE,
    );
  }

  const salesMarketHomeInsurances = markupHomeInsurance.find(
    (homeInsurance: IMarkupHomeInsurance) =>
      homeInsurance.productMarketCountryCode === productMarketCountryCode &&
      homeInsurance.salesMarketCountryCode === salesMarketCountryCode,
  );

  if (!productMarketHomeInsurances || !salesMarketHomeInsurances) {
    throw new Error(
      `[markupCalculator] No HomeInsurances found for productMarket:${productMarketCountryCode} and sales market: ${salesMarketCountryCode}`,
    );
  }
  // The computation are made in the owner currency
  return {
    productMarket: productMarketHomeInsurances.price,
    salesMarket: salesMarketHomeInsurances.price,
  };
};

export const getMarkupCacadeForSeason = (
  filteredMarkupData: Array<IMarketTypes<IMarkupValue>>,
  seasonName: string,
): IMarketTypes<IMarkupValue> => {
  const markupCascadeForSeason = filteredMarkupData.find(
    (markupElement) => markupElement.salesMarket.seasonName === seasonName,
  );
  if (!markupCascadeForSeason) {
    throw new Error(`[markupCalculator] No markup cascade for season ${seasonName}`);
  }
  return markupCascadeForSeason;
};

export const getMarkupCascades = async (
  seasonMidlinePriceIndex: ISeasonalPrices,
  cascadeType: string,
  country: ICountry,
  year: number,
): Promise<Array<IMarketTypes<IMarkupData>>> => {
  const markupData = await appDatabase.markupData.toArray();
  const markupMappings = await appDatabase.markupMappings.toArray();
  const cascadeTypes = await appDatabase.cascadeTypes.toArray();
  const filteredCascade = getCascadeType(
    cascadeTypes.filter((c) => c.year === year),
    cascadeType,
  );
  const filteredMapping = getFilteredMapping(
    markupMappings.filter((m) => m.year === year),
    filteredCascade.name,
    country.code,
  );
  const markupCascadePriceRanges = getFilteredMarkups(
    markupData.filter((m) => m.year === year),
    filteredMapping,
  );

  const markupCascades = selectMarkupWithinPriceRange(
    seasonMidlinePriceIndex,
    markupCascadePriceRanges,
    country.code,
  );

  // eslint-disable-next-lineno-console
  console.log('markupCascadePriceRanges', markupCascadePriceRanges);

  return markupCascades;
};
