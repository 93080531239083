import ROUTES from 'constants/routes';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { getIsLoggedIn } from 'stateManagement/Login';
import { RootState } from 'stateManagement/types';

const Login = lazy(() => import('./pages/Login'));
const NewQuote = lazy(() => import('./pages/NewQuote'));
const Characteristics = lazy(() => import('./pages/Characteristics'));
const Quote = lazy(() => import('./pages/Quote'));
const PricingRange = lazy(() => import('./pages/PricingRange'));
const ShortBreaksDetails = lazy(() => import('./pages/ShortBreaksDetails'));
const AllPropertyQuotes = lazy(() => import('./pages/AllPropertyQuotes'));
const ComparisonDetails = lazy(() => import('./pages/ComparisonDetails'));

const LoggedOutTree: React.FunctionComponent = () => (
  <Switch>
    <Route path={ROUTES.LOGIN} component={Login} />
    <Redirect to={{ pathname: ROUTES.LOGIN }} />
  </Switch>
);

const LoggedInTree: React.FunctionComponent = () => (
  <Switch>
    <Route path={ROUTES.LOGIN} component={Login} />
    <Route path={ROUTES.NEW_QUOTE} component={NewQuote} />
    <Route path={ROUTES.CHARACTERISTICS} component={Characteristics} />
    <Route path={ROUTES.QUOTE} component={Quote} />
    <Route path={ROUTES.PRICING_RANGE} component={PricingRange} />
    <Route path={ROUTES.SHORTBREAK_DETAILS} component={ShortBreaksDetails} />
    <Route path={ROUTES.ALL_QUOTES} component={AllPropertyQuotes} />
    <Route path={ROUTES.COMPARISON_DETAILS} component={ComparisonDetails} />
    <Redirect to={{ pathname: ROUTES.ALL_QUOTES }} />
  </Switch>
);

interface IProps {
  isLoggedIn: boolean;
}

const routes: React.FunctionComponent<IProps> = ({ isLoggedIn }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isLoggedIn ? <LoggedInTree /> : <LoggedOutTree />}
    </Suspense>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: getIsLoggedIn(state),
});

export default connect(mapStateToProps)(routes);
