import { IMarkupSeasonalExtras, ISeason } from 'services/appDatabase';
import {
  findMidlinePriceForSeasonUUID,
  findMidlineSalesPriceForSeasonUUID,
} from 'services/averagePriceChange';
import { IRevenueResult, ISeasonalPrices, IWeekPricingData } from 'services/engine/types';

export const buildSeasonMidlinePriceIndex = (
  seasons: ISeason[],
  yearlyPricingResults: IWeekPricingData[],
  convertToOwnerCurrency: (value: number) => number,
): ISeasonalPrices => {
  return seasons.reduce((acc: ISeasonalPrices, season: ISeason) => {
    const midlineSeasonPrice = findMidlinePriceForSeasonUUID(season.uuid, yearlyPricingResults);
    if (midlineSeasonPrice === undefined) {
      throw new Error(
        `[markupCalculator] Could not find a midline price for season name ${season.name}`,
      );
    }
    return { ...acc, [season.name]: convertToOwnerCurrency(midlineSeasonPrice) };
  }, {});
};

export const buildSeasonMidlineSalesPriceIndex = (
  seasons: ISeason[],
  yearlyPricingResults: IWeekPricingData[],
): ISeasonalPrices => {
  return seasons.reduce((acc: ISeasonalPrices, season: ISeason) => {
    const midlineSeasonPrice = findMidlineSalesPriceForSeasonUUID(
      season.uuid,
      yearlyPricingResults,
    );
    if (midlineSeasonPrice === undefined) {
      throw new Error(
        `[markupCalculator] Could not find a midline price for season name ${season.name}`,
      );
    }
    return { ...acc, [season.name]: midlineSeasonPrice };
  }, {});
};

export const getRevenueObjectForFiltering = (revenueResults: IRevenueResult[]): IRevenueResult => {
  // We want the revenue results of the very last year
  const lastRevenueResults = revenueResults.slice(-1).pop();

  if (!lastRevenueResults) {
    throw new Error(`[markupCalculator] Could not get a revenue result to process markups`);
  }

  return lastRevenueResults;
};

export const getCancellationInsurance = (markupSeasonalExtra: IMarkupSeasonalExtras) => {
  return 1 + markupSeasonalExtra.cancellationInsurancePercentage / 100;
};

export const roundGuestPrice = (price: number) => {
  const newPrice = Math.floor(price + 0.99);
  const lastTwoDigits = newPrice % 100;
  let valueAppend = 0;
  if (lastTwoDigits < 5) {
    // Set to 9
    valueAppend = 9 - lastTwoDigits;
  } else if (lastTwoDigits === 5) {
    // Round up to 9
    valueAppend = 4;
  } else if (lastTwoDigits <= 94 && lastTwoDigits % 10 !== 0) {
    // Round up to 10
    valueAppend = 10 - (lastTwoDigits % 10);
  } else if (lastTwoDigits > 94) {
    // Round to 99
    valueAppend = 99 - lastTwoDigits;
  }

  return newPrice + valueAppend;
};
