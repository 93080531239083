import { NUMBER_OF_BENCHMARK_PROPERTIES } from 'constants/engine';
import { ILatLon, INewProperty, IUpstreamProperty } from '../appDatabase';
import { computeDistance } from './utils';

import BaseEngine from './base';
import { IBenchmarkEngine } from './types';

abstract class BenchmarkEngine extends BaseEngine implements IBenchmarkEngine {
  private numberOfSimilarProperties: number = NUMBER_OF_BENCHMARK_PROPERTIES;

  public async compute(newProperty: INewProperty): Promise<IUpstreamProperty[]> {
    const similarProperties = await this.getSimilarProperties(newProperty);

    if (similarProperties) {
      return this.filterNearestProperties(
        await this.getILatLong(newProperty),
        similarProperties,
        this.numberOfSimilarProperties,
      );
    }
    return [];
  }

  protected abstract getSimilarProperties(newProperty: INewProperty): Promise<IUpstreamProperty[]>;

  protected abstract getILatLong(newProperty: INewProperty): Promise<ILatLon>;

  protected abstract getPropertyFilter(
    newProperty: INewProperty,
  ): (property: IUpstreamProperty) => boolean;

  protected async getDBUpstreamProperties(
    minBedrooms: number,
    maxBedrooms: number,
    newProperty: INewProperty,
  ): Promise<IUpstreamProperty[]> {
    return this.db.upstreamProperties
      .where('noOfBedrooms')
      .between(minBedrooms, maxBedrooms, true, true)
      .and(this.getPropertyFilter(newProperty))
      .sortBy('serviceId');
  }

  private filterNearestProperties(
    latLon: ILatLon,
    similarProperties: IUpstreamProperty[],
    numberOfProperties: number,
  ): IUpstreamProperty[] {
    const similarPropertiesWithDistance = similarProperties.map((property: IUpstreamProperty) => ({
      ...property,
      distance: computeDistance(latLon, {
        latitude: parseFloat(property.latitude),
        longitude: parseFloat(property.longitude),
      }),
    }));

    similarPropertiesWithDistance.sort(
      (propertyA, propertyB) => propertyA.distance - propertyB.distance,
    );

    return similarPropertiesWithDistance.slice(0, numberOfProperties);
  }
}

export default BenchmarkEngine;
