import { getIn } from 'formik';
import { DateTime } from 'luxon';
import { FormValues } from 'pages/Quote/AdjustOccupancyDialog/AdjustOccupancyDialog.novasol';
import appDatabase, { INewPropertyQuote } from './appDatabase';
import { IRange } from './engine/types';
import { selectWeekMappingsForYearAndCalendar } from './engine/utils';

const isValueNullOrUndefined = (value: any) => value === undefined || value === null;
export const isValueEmpty = (value: any) => isValueNullOrUndefined(value) || value === '';

export const isRequired =
  (formatMessage: ReactIntl.InjectedIntl['formatMessage']) => (value: any) => {
    if (isValueEmpty(value)) {
      return formatMessage({ id: 'validation.required' });
    }
    return undefined;
  };

export const conditionalRequired = (
  formatMessage: ReactIntl.InjectedIntl['formatMessage'],
  condition: boolean,
) => {
  if (condition) {
    return isRequired(formatMessage);
  } else {
    return () => undefined;
  }
};

export const numberValidator =
  (
    formatMessage: ReactIntl.InjectedIntl['formatMessage'],
    min: number | null | undefined,
    max: number | null | undefined,
  ) =>
  (value: number | undefined) => {
    const maxValue = max || null;
    const minValue = min || null;

    if (isValueNullOrUndefined(value)) {
      return formatMessage({ id: 'validation.required' });
    }
    if (typeof value !== 'number') {
      return formatMessage({ id: 'validation.number.invalid' });
    }
    if (minValue && !maxValue && value < minValue) {
      return formatMessage({ id: 'validation.number.minimum' }, { minValue });
    }
    if (maxValue && !minValue && value > maxValue) {
      return formatMessage({ id: 'validation.number.maximum' }, { maxValue });
    }
    if ((minValue !== null && value < minValue) || (maxValue !== null && value > maxValue)) {
      return formatMessage({ id: 'validation.number.range' }, { maxValue, minValue });
    }
    return undefined;
  };

export const occupancyAdjustmentValidator =
  (
    formatMessage: ReactIntl.InjectedIntl['formatMessage'],
    values: FormValues,
    name: string,
    max: number,
  ) =>
  (val: number): string | undefined => {
    const range = getIn(values, name) as IRange;
    if (range.lowest < 0) {
      return formatMessage({ id: 'validation.occupancy.minimum' });
    } else if (range.lowest > range.highest) {
      return formatMessage({ id: 'validation.occupancy.lowerLessThanHigher' });
    } else if (range.highest > max) {
      return formatMessage({ id: 'validation.occupancy.higherMaximum' });
    }
    return;
  };

export const isQuoteValidForSubmission = (property: INewPropertyQuote) => {
  return property.contractNumber !== '';
};

export const notRequiredNumberValidator =
  (
    formatMessage: ReactIntl.InjectedIntl['formatMessage'],
    min: number | null | undefined,
    max: number | null | undefined,
  ) =>
  (value: number | undefined) => {
    if (value) {
      return numberValidator(formatMessage, min, max)(value);
    }
    return undefined;
  };

export const validateCalendar =
  (formatMessage: ReactIntl.InjectedIntl['formatMessage'], year: number | undefined) =>
  async (calendarUUID: string) => {
    try {
      const calendar = await appDatabase.calendar.get(calendarUUID);
      if (!calendar) {
        return undefined;
      }

      if (!year) {
        return formatMessage({ id: 'validation.date.firstAvailable' });
      }

      await selectWeekMappingsForYearAndCalendar(year, calendar.name);
      return undefined;
    } catch (error) {
      console.warn('[Validation] Calendar type dropdown.', error);
      return formatMessage({ id: 'validation.date.noCascadesFound' }, { year });
    }
  };

export const validateDate =
  (
    formatMessage: ReactIntl.InjectedIntl['formatMessage'],
    minDateString: string | null,
    maxDateString: string | null,
  ) =>
  (dateString: string) => {
    if ((!minDateString && !maxDateString) || !dateString) {
      return undefined;
    }
    const currentDate = DateTime.fromISO(dateString);
    const maxDate = DateTime.fromISO(maxDateString || '');
    const minDate = DateTime.fromISO(minDateString || '');

    if (minDateString && !maxDateString && minDate > currentDate) {
      return formatMessage({ id: 'validation.date.minimum' }, { minDate: minDateString });
    }

    if (!minDateString && maxDateString && maxDate < currentDate) {
      return formatMessage({ id: 'validation.date.maximum' }, { maxDate: maxDateString });
    }

    if (minDateString && maxDateString && (minDate > currentDate || maxDate < currentDate)) {
      return formatMessage(
        { id: 'validation.date.between' },
        { minDate: minDateString, maxDate: maxDateString },
      );
    }

    return undefined;
  };
