import { INewProperty } from 'services/appDatabase';
import PriceAdjustedOccupancyEngine from 'services/engine/priceAdjustedOccupancy';
import { getOccupancyResultFromOccupancyWeeks } from 'services/occupancy';
import { capBetween } from 'services/utils/numberUtils';
import { WEEK_LENGTH } from '../constants';
import { PricingEngineException } from '../exceptions';
import {
  IAveragePriceChangeResult,
  IOccupancyResult,
  IPriceAdjustedOccupancyEngine,
} from '../types';

class NovasolPriceAdjustedOccupancyEngine
  extends PriceAdjustedOccupancyEngine
  implements IPriceAdjustedOccupancyEngine
{
  applyOccupancyAdjustmentFormula(occupancy: number, apc: number, elasticity: number): number {
    return capBetween(occupancy * (1 + elasticity * apc));
  }

  async computePriceAdjustedOccupancy(
    newProperty: INewProperty,
    previousOccupancyResults: IOccupancyResult[],
    averagePriceChangeResults: IAveragePriceChangeResult[],
  ): Promise<IOccupancyResult[]> {
    const elasticity = await this.getElasticity(newProperty);

    const result = previousOccupancyResults.map((occupancyResult) => {
      const year = occupancyResult.year;
      const averagePriceChangeResult = averagePriceChangeResults.find(
        (averagePriceChange) => averagePriceChange.year === year,
      );
      if (!averagePriceChangeResult) {
        throw new PricingEngineException(
          `[PriceAdjustedOccupancy] Could not find an average price change for year ${year}`,
        );
      }

      if (!occupancyResult.weeks) {
        throw new PricingEngineException('[PriceAdjustedOccupancy] No occupancy weeks found');
      }

      const seasonAPCs = averagePriceChangeResult.seasonsAveragePriceChange;
      if (!seasonAPCs) {
        throw new PricingEngineException(
          `[PriceAdjustedOccupancy] Couldn't find the average price change by season`,
        );
      }

      const adjustedWeeks = occupancyResult.weeks.map((week) => {
        const apc = seasonAPCs[week.season.name];
        if (apc === undefined) {
          throw new PricingEngineException(`Couldn't find the apc for season ${week.season.name}`);
        }
        return {
          ...week,
          commercialOccupancy: {
            lowest: this.applyOccupancyAdjustmentFormula(
              week.commercialOccupancy.lowest,
              apc,
              elasticity,
            ),
            highest: this.applyOccupancyAdjustmentFormula(
              week.commercialOccupancy.highest,
              apc,
              elasticity,
            ),
          },
        };
      });

      return getOccupancyResultFromOccupancyWeeks(adjustedWeeks);
    });

    // eslint-disable-next-lineno-console
    console.log('Price Adjusted Occupancy result: ', result);

    return result;
  }

  protected async getElasticity(newProperty: INewProperty): Promise<number> {
    const calendar = await this.selectCalendar(newProperty.calendar);
    if (!calendar) {
      throw new PricingEngineException(`Could not find the calendar in the DB`);
    }
    return calendar.elasticity;
  }

  protected async getLengthOfStay(newProperty: INewProperty): Promise<number> {
    return Promise.resolve(WEEK_LENGTH);
  }
}

export default NovasolPriceAdjustedOccupancyEngine;
