import { ICurrency } from './appDatabase';

const getEngineTimeLabel = (engineName: string) => `Time for ${engineName.toLowerCase()}`;

export const startEngineLogs = (engineName: string, collapsed = true) => {
  // eslint-disable no-console
  (collapsed ? console.groupCollapsed : console.group)(
    `%c${engineName}`,
    'color: #2EA68D; text-decoration: underline;',
  );
  console.time(getEngineTimeLabel(engineName));
  // eslint-enable no-console
};

export const endEngineLogs = (engineName: string) => {
  // eslint-disable no-console
  console.timeEnd(getEngineTimeLabel(engineName));
  console.groupEnd();
  // eslint-enable no-console
};

export const logCurrency = (description: string, currency: ICurrency) => {
  // eslint-disable no-console
  console.groupCollapsed(`%c${description}`, 'color: orange; text-decoration: underline;');
  console.log(currency);
  console.groupEnd();
  // eslint-enable no-console
};
