import { AppDatabase } from 'services/appDatabase';
import Engine from 'services/engine/engine';
import NovasolAveragePriceChangeEngine from 'services/engine/novasol/averagePriceChange';
import NovasolPriceAdjustedOccupancyEngine from 'services/engine/novasol/priceAdjustedOccupancy';
import NovasolRevenueEngine from 'services/engine/novasol/revenue';
import NovasolShortBreakEngine from 'services/engine/novasol/shortBreak';
import SeasonPricingEngine from 'services/engine/seasonPricing';
import NovasolBenchmarkEngine from './benchmark';
import NovasolOccupancyEngine from './occupancy';
import NovasolPricingEngine from './pricing';

export default (database: AppDatabase) =>
  new Engine(
    database,
    NovasolBenchmarkEngine,
    NovasolOccupancyEngine,
    NovasolPricingEngine,
    NovasolRevenueEngine,
    NovasolShortBreakEngine,
    NovasolAveragePriceChangeEngine,
    NovasolPriceAdjustedOccupancyEngine,
    SeasonPricingEngine,
  );
