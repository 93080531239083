import { INewProperty, IUpstreamProperty } from 'services/appDatabase';
import { calculateAveragePriceChange, findSeasonPrice } from 'services/averagePriceChange';
import AveragePriceChangeEngine from 'services/engine/averagePriceChange';
import { PricingEngineException } from '../exceptions';
import {
  IAveragePriceChangeEngine,
  IAveragePriceChangeResult,
  IPricingResult,
  IShortBreakResult,
} from '../types';
import { getSeasonsForCalendar } from '../utils';

class NovasolAveragePriceChangeEngine
  extends AveragePriceChangeEngine
  implements IAveragePriceChangeEngine
{
  async computeAveragePriceChange(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    ownerShortBreak: IShortBreakResult,
    adjustedPricing: IPricingResult[],
    originalPricing: IPricingResult[],
  ): Promise<IAveragePriceChangeResult[]> {
    // returns all possible seasons
    const seasons = await getSeasonsForCalendar(newProperty.calendar);

    return originalPricing.map((yearOriginalPricing) => {
      const year = yearOriginalPricing.year;
      const seasonsAveragePriceChange: { [seasonName: string]: number } = seasons.reduce(
        (acc, season) => {
          const seasonPriceOriginal = findSeasonPrice(
            seasons,
            season.name,
            yearOriginalPricing.yearData,
          );
          const yearAdjustedPricing = adjustedPricing.find(
            (yearPricing) => yearPricing.year === year,
          );
          if (!yearAdjustedPricing) {
            throw new PricingEngineException(
              `Could not find adjusted pricing results for year ${year}`,
            );
          }
          const seasonPriceAdjusted = findSeasonPrice(
            seasons,
            season.name,
            yearAdjustedPricing.yearData,
          );

          if (!seasonPriceOriginal || !seasonPriceAdjusted) {
            return {
              ...acc,
            };
          }

          return {
            ...acc,
            [season.name]: calculateAveragePriceChange(seasonPriceOriginal, seasonPriceAdjusted),
          };
        },
        {},
      );

      const result = {
        year,
        averagePriceChange: 0,
        seasonsAveragePriceChange,
      };

      // eslint-disable-next-line
      console.log('Average Price Change:', result);

      return result;
    });
  }
}

export default NovasolAveragePriceChangeEngine;
