import { MARKUP_COUNTRY_FALLBACK } from 'constants/countries';
import { ISeasonalPrices, ISeasonUuidPrices } from 'services/engine/types';
import appDatabase, { dummyCurrencyFee, IMarkupValue, INewProperty } from '../appDatabase';
import { commissionToMarkup, getSeasonMap } from '../engine/utils';
import { calculatePriceWithMarkupForSeason } from './markupCalculator';
import {
  getFilteredMarkupSeasonalExtras,
  getHomeInsuranceValues,
  getPriceMappingForCalendar,
} from './markupCalculator.getters';
import { roundGuestPrice } from './markupCalculator.helpers';

export async function calculateGuestPrices(
  newProperty: INewProperty,
  uuidPricingResultsYearOne: ISeasonUuidPrices,
  commission: number,
): Promise<ISeasonalPrices> {
  const country = await appDatabase.countries.toCollection().first();

  if (country === undefined) {
    throw new Error(`[markupCalculator] Failed to find country`);
  }

  const markupHomeInsurance = await appDatabase.markupHomeInsurance.toArray();
  const priceMappings = await appDatabase.priceIdMappings.toArray();
  const markupSeasonalExtras = await appDatabase.markupSeasonalExtras.toArray();

  const filteredHomeInsurances = getHomeInsuranceValues(
    country.code,
    MARKUP_COUNTRY_FALLBACK.INSURANCE,
    markupHomeInsurance.filter((i) => i.year === newProperty.firstAvailable.year),
  );

  const filteredPriceMapping = await getPriceMappingForCalendar(
    newProperty.calendar,
    priceMappings,
    newProperty.firstAvailable.year.toString(),
  );
  const filteredSeasonalExtras = getFilteredMarkupSeasonalExtras(
    country.code,
    filteredPriceMapping,
    markupSeasonalExtras,
  );

  // eslint-disable-next-lineno-console
  console.log('filteredHomeInsurances', filteredHomeInsurances);
  // eslint-disable-next-lineno-console
  console.log('filteredSeasonalExtras', filteredSeasonalExtras);

  const equivalentMarkup: IMarkupValue = { seasonName: '', markup: commissionToMarkup(commission) };
  const seasonMap = await getSeasonMap();

  const result: ISeasonalPrices = Object.keys(uuidPricingResultsYearOne).reduce(
    (seasonalPricesWithMarkup, seasonUuid) => {
      const seasonName = seasonMap[seasonUuid];
      const ownerPrice = uuidPricingResultsYearOne[seasonUuid];
      const guestPrice = calculatePriceWithMarkupForSeason(
        seasonName,
        ownerPrice,
        equivalentMarkup,
        filteredSeasonalExtras.productMarket,
        dummyCurrencyFee,
        filteredHomeInsurances.productMarket,
        country,
      );

      return {
        ...seasonalPricesWithMarkup,
        [seasonUuid]: roundGuestPrice(guestPrice),
      };
    },
    {},
  );

  // eslint-disable-next-lineno-console
  console.log('Seasonal guest prices ', result);

  return result;
}
