import { RADIUS } from 'constants/engine';
import { UUIDNameMapping } from 'hooks/useCascadeSeasonMapping';
import _range from 'lodash/range';
import { DateTime } from 'luxon';
import moment from 'moment';
import appDatabase, { IUpstreamProperty } from 'services/appDatabase';
import { scalarIRanges } from 'services/utils/iRange';
import { ILatLon, ISeason, IWeekMapping } from '../appDatabase';
import { PricingEngineException } from './exceptions';
import { INovasolOccupancy } from './novasol/occupancy';
import { IRange, ISeasonUuidPrices } from './types';

export const computeDistance = (pointA: ILatLon, pointB: ILatLon): number => {
  const dLat = deg2rad(pointB.latitude - pointA.latitude);
  const dLon = deg2rad(pointB.longitude - pointA.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(pointA.latitude)) *
      Math.cos(deg2rad(pointB.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return Math.floor(RADIUS * c);
};

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export const getPercentile = (array: number[], percentile: number) => {
  const sortedArray = array.slice().sort((a, b) => a - b);
  const index = array.length * percentile;
  return index % 1 === 0
    ? (sortedArray[index - 1] + sortedArray[index]) / 2
    : sortedArray[Math.floor(index)];
};

export const getLeadTime = (elementDate: DateTime, goLiveDate: DateTime) => {
  const diffInMonthsGoLive = Math.ceil(elementDate.diff(goLiveDate, 'months').as('months'));
  return Math.max(0, diffInMonthsGoLive);
};

export const getPostcodeSector = (postcode: string): string => {
  const sector = postcode.trim();
  return sector.substring(0, sector.length - 2);
};

export const weeksInYear = (year: number): number =>
  Math.max(moment(new Date(year, 11, 31)).isoWeek(), moment(new Date(year, 11, 31 - 7)).isoWeek());

export const roundEstimatedPrice = (price: number, percentage: number, rounding: number) =>
  Math.round((price * (1 + percentage)) / rounding) * rounding;

export const commercialRounding = (price: number) => {
  const lastDigit = price % 10;
  const allOtherDigits = Math.floor(price / 10) * 10;
  return allOtherDigits + (lastDigit <= 5 ? 5 : 9);
};

export const selectWeekMappingsForYearAndCalendar = async (
  currentYear: number,
  calendarName: string,
): Promise<IWeekMapping[]> => {
  let res: IWeekMapping[] = [];
  const MAX_YEARS = 3;
  const yearsToTest = getYearsToTest(currentYear, MAX_YEARS);

  for (const year of yearsToTest) {
    res = await appDatabase.weekMapping
      .where('[currentYear+bookingType+calendarName]')
      .equals([year, 'Fullweek', calendarName])
      .sortBy('currentWeek');

    if (res.length > 0) {
      return res.map((week) => ({ ...week, currentYear: year, previousYear: year - 1 }));
    }
  }

  throw new PricingEngineException(
    `No week mappings found between ${Math.min(...yearsToTest)} and ${Math.max(...yearsToTest)}`,
  );
};

export const removeInvalidNovasolProperties = (properties: IUpstreamProperty[]) =>
  properties.filter((property) => !!property.novasolSeasonAGuestPrice);

export const sumOnField = <T extends { [key in Key]: number }, Key extends string>(
  arr: T[],
  field: Key,
) => arr.reduce((acc, value) => acc + value[field], 0);

export const modifySeasonAOccupancy = (occupancies: INovasolOccupancy[]) => {
  const maxOccupancy: IRange = { highest: 1, lowest: 1 };
  const modifiedOcupancies = occupancies.map((occupancyObject) =>
    occupancyObject.season.name === 'A'
      ? { ...occupancyObject, occupancyRange: maxOccupancy }
      : occupancyObject,
  );
  // eslint-disable-next-lineno-console
  console.log('Occupancy results with bumped season A', modifiedOcupancies);
  return modifiedOcupancies;
};

export const markupToCommission = (markup: number) => {
  if (markup < 1 || markup > 100) {
    throw Error('Markup should be between 1 and 100');
  }

  return (markup - 1) / markup;
};

export const commissionToMarkup = (commission: number) => {
  if (commission < 0 || commission > 1) {
    throw Error('Commission should be between 0 and 1');
  }

  return 1 / (1 - commission) - 1 + 1;
};

let seasonCache: ISeason[];

export const getAllSeasons = async (): Promise<ISeason[]> => {
  if (!seasonCache) {
    seasonCache = await appDatabase.season.toCollection().sortBy('name');
  }
  return seasonCache;
};

export const getSeasonMap = async (): Promise<Record<string, string>> => {
  const seasons = await getAllSeasons();
  return seasons.reduce((agg, season) => ({ ...agg, [season.uuid]: season.name }), {});
};

export const getSeasonName = async (uuid: string) => (await getSeasonMap())[uuid];

export const toSeasonNamePrices = async (uuidPrices: ISeasonUuidPrices) => {
  const seasonMap = await getSeasonMap();
  return Object.keys(uuidPrices).reduce(
    (aggregator, uuid) => ({ ...aggregator, [seasonMap[uuid]]: uuidPrices[uuid] }),
    {},
  );
};

export const getSeasonsForCalendar = async (
  calendarUUID: string,
  years: number[] = [],
): Promise<ISeason[]> => {
  let cascadeTypesForCurrentCalendar = await appDatabase.cascadeTypes
    .where('calendar')
    .equals(calendarUUID)
    .sortBy('name');

  if (years.length > 0) {
    cascadeTypesForCurrentCalendar = cascadeTypesForCurrentCalendar.filter((cascade) =>
      years.includes(cascade.year),
    );
  }

  const mappingUUIDtoName: UUIDNameMapping = cascadeTypesForCurrentCalendar.reduce(
    (acc: UUIDNameMapping, cascadeType) => ({ ...acc, [cascadeType.uuid]: cascadeType.name }),
    {},
  );
  const cascadeTypesUUids = Object.keys(mappingUUIDtoName);

  const cascadeDataForCurrentTypes = await appDatabase.cascadeData
    .filter((cascade) => cascadeTypesUUids.includes(cascade.cascadeType))
    .sortBy('name');

  const cascadeDataWithCascadeName = cascadeDataForCurrentTypes.map((cascadeData) => ({
    ...cascadeData,
    cascadeTypeName: mappingUUIDtoName[cascadeData.cascadeType],
  }));

  const allPossibleSeasons = new Set(
    cascadeDataWithCascadeName.map((cascadeElement) => cascadeElement.season),
  );
  const allPossibleSeasonsAsList = Array.from(allPossibleSeasons);

  const seasons = await appDatabase.season
    .filter((season) => allPossibleSeasonsAsList.includes(season.uuid))
    .sortBy('name');

  return seasons;
};

export const getYearsToTest = (currentYear: number, maxYears: number) => {
  const forwardYears = _range(currentYear + 1, currentYear + maxYears + 1);
  const backwardYears = _range(currentYear - 1, currentYear - (maxYears + 1));
  return [currentYear, ...forwardYears, ...backwardYears];
};

export const computeCommercialOccupancy = (
  estimatedOccupancy: IRange,
  open: boolean,
  penalty: number,
  override: boolean,
): IRange => {
  // Apply penalty
  let res = scalarIRanges(estimatedOccupancy, penalty);

  // Apply override
  res = {
    lowest: override ? 1 : res.lowest,
    highest: override ? 1 : res.highest,
  };

  // Only count the occupancies if the week is open
  res = scalarIRanges(res, +open);

  return res;
};
