import { DateTime } from 'luxon';
import {
  IBookingWindowPenalty,
  INewProperty,
  IOccupancyCoefficient,
  ISeason,
  IUpstreamProperty,
  IWeekMapping,
} from 'services/appDatabase';
import { isWeekInRange } from 'services/dates';
import OccupancyEngine from 'services/engine/occupancy';
import { IOccupancyEngine, IOccupancyResult, IRange } from 'services/engine/types';
import { getOccupancyResultFromOccupancyWeeks } from 'services/occupancy';
import { addScalarToIRange } from 'services/utils/iRange';
import { INTERCEPT } from '../constants';
import { PricingEngineException } from '../exceptions';
import { computeCommercialOccupancy, selectWeekMappingsForYearAndCalendar } from '../utils';

export interface IOccupancyCascade {
  season: ISeason;
  value: number;
}

export interface INovasolOccupancy {
  season: ISeason;
  occupancyRange: IRange;
}

interface IYearOccupancyWeeks {
  penalty: IBookingWindowPenalty | undefined;
  commercialOccupancy: IRange;
  calendarName: string;
  weekNumber: number;
  month: number;
  year: number;
  season: ISeason;
  occupancyRange: IRange;
  occupancyOverride: boolean;
  open: boolean;
}

class NovasolOccupancyEngine extends OccupancyEngine implements IOccupancyEngine {
  protected varianceValue = 0.125;

  public async computeOccupancy(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
  ): Promise<IOccupancyResult[]> {
    const pricingRegionCode = await this.getPricingRegionCodeForNovasol(newProperty);
    // eslint-disable-next-line
    console.log(`pricingRegionCode: "${pricingRegionCode}"`);

    const seasonAEstimatedOccupancy: IRange = await this.getEstimatedOccupancy(
      newProperty,
      benchmarkProperties,
      pricingRegionCode,
    );

    const cascadeType = await this.getDBCascadeTypeForUUID(newProperty.cascadeType);

    if (!cascadeType) {
      throw new PricingEngineException(
        `[computeOccupancy] Could not find cascadeType for uuid ${newProperty.cascadeType}`,
      );
    }

    const [yearOne, yearTwo] = this.getYears(newProperty);

    const priceCascadeUUID = {
      yearOne: await this.getDBCascadeUUIDWithSameNameAs(
        newProperty.cascadeType,
        newProperty.calendar,
        yearOne,
      ),
      yearTwo: await this.getDBCascadeUUIDWithSameNameAs(
        newProperty.cascadeType,
        newProperty.calendar,
        yearTwo,
      ),
    };

    const occupancyCascade = {
      yearOne: await this.selectOccupancyCascade(pricingRegionCode, priceCascadeUUID.yearOne),
      yearTwo: await this.selectOccupancyCascade(pricingRegionCode, priceCascadeUUID.yearTwo),
    };

    // eslint-disable-next-line
    console.log('OccupancyCascades', occupancyCascade);

    const estimatedOccupancies: { [year: number]: INovasolOccupancy[] } = {
      [yearOne]: this.applyOccupancyCascade(seasonAEstimatedOccupancy, occupancyCascade.yearOne),
      [yearTwo]: this.applyOccupancyCascade(seasonAEstimatedOccupancy, occupancyCascade.yearTwo),
    };

    // eslint-disable-next-line
    console.log('estimatedOccupancies', estimatedOccupancies);

    const yearOneOccupancyWeeks = await this.buildWeeksWithOccupancy(
      yearOne,
      newProperty.calendar,
      estimatedOccupancies[yearOne],
      newProperty.firstAvailable,
      newProperty.goLive,
      DateTime.fromISO(newProperty.availabilityOpenDate),
      DateTime.fromISO(newProperty.availabilityCloseDate),
    );

    const yearTwoOccupancyWeeks = await this.buildWeeksWithOccupancy(
      yearTwo,
      newProperty.calendar,
      estimatedOccupancies[yearTwo],
      newProperty.firstAvailable,
      newProperty.goLive,
      DateTime.fromISO(newProperty.availabilityOpenDate),
      DateTime.fromISO(newProperty.availabilityCloseDate),
    );

    // eslint-disable no-console
    console.log(`OccupancyWeeks with penalty (${yearOne})`, yearOneOccupancyWeeks);
    console.log(`OccupancyWeeks with penalty (${yearTwo})`, yearTwoOccupancyWeeks);
    // eslint-enable no-console

    const occupancyResult = [
      getOccupancyResultFromOccupancyWeeks(yearOneOccupancyWeeks),
      getOccupancyResultFromOccupancyWeeks(yearTwoOccupancyWeeks),
    ];
    // eslint-disable-next-line
    console.log('Occupancy Result', occupancyResult);

    return occupancyResult;
  }

  protected applyOccupancyCascade(
    seasonAOccupancy: IRange,
    occupancyCascade: IOccupancyCascade[],
  ): INovasolOccupancy[] {
    return occupancyCascade.map((cascade) => ({
      season: cascade.season,
      // season A occupancy was already capped (0.75 - 1.00) in getEstimatedOccupancy but the intention here is to try and lower the occupancy a bit
      occupancyRange: this.capMinMaxOccupancy(addScalarToIRange(seasonAOccupancy, cascade.value)),
    }));
  }

  protected capMaximumOccupancy = (occupancy: IRange): IRange => {
    return {
      lowest: Math.min(occupancy.lowest, 1 - 2 * this.varianceValue),
      highest: Math.min(occupancy.highest, 1),
    };
  };

  protected capMinimumOccupancy = (occupancy: IRange): IRange => {
    return {
      lowest: Math.max(occupancy.lowest, 0),
      highest: Math.max(occupancy.highest, 2 * this.varianceValue),
    };
  };

  protected capMinMaxOccupancy = (occupancy: IRange): IRange => {
    return this.capMinimumOccupancy(this.capMaximumOccupancy(occupancy));
  };

  protected getAdjustedOCcupancy(
    occupancyCoefficients: IOccupancyCoefficient[],
    benchmarkProperties: IUpstreamProperty[],
  ): IRange {
    const baseOccupancyForRegion = occupancyCoefficients.find(
      (occupancyCoefficient) => occupancyCoefficient.featureName === INTERCEPT,
    );
    if (!baseOccupancyForRegion) {
      throw new PricingEngineException(
        '[AdjustedOccupancy] Could not find an intercept value in the occupancy table',
      );
    }

    return {
      lowest: baseOccupancyForRegion.value * (1 - this.varianceValue),
      highest: baseOccupancyForRegion.value * (1 + this.varianceValue),
    };
  }

  protected async buildWeeksWithOccupancy(
    year: number,
    calendarUUID: string,
    estimatedOccupancies: INovasolOccupancy[],
    firstAvailableDate: DateTime,
    goLiveDate: DateTime,
    openDate: DateTime,
    closeDate: DateTime,
  ): Promise<IYearOccupancyWeeks[]> {
    const calendar = await this.selectCalendar(calendarUUID);
    if (!calendar) {
      throw new PricingEngineException(`Could not find a calendar with this uuid: ${calendarUUID}`);
    }

    const weekMapping = await selectWeekMappingsForYearAndCalendar(year, calendar.name);

    const isWeekAfterFirstAvailable = (firstAvailable: DateTime, week: IWeekMapping): boolean =>
      firstAvailable.year < year || firstAvailable.weekNumber < week.currentWeek;

    const weeksWithOccupancy = weekMapping.map((week) => {
      const occupancyWithSeason = estimatedOccupancies.find(
        (occupancy) => occupancy.season.uuid === week.season,
      );
      if (!occupancyWithSeason) {
        throw new PricingEngineException(
          `Could not find an estimated occupancy for season: ${week.season}`,
        );
      }

      return {
        calendarName: week.calendarName,
        weekNumber: week.currentWeek,
        month: week.currentMonth,
        year,
        season: occupancyWithSeason.season,
        occupancyRange: occupancyWithSeason.occupancyRange,
        occupancyOverride: week.weekOccupancyOverride,
        open:
          isWeekAfterFirstAvailable(firstAvailableDate, week) &&
          isWeekInRange(week.currentWeek, openDate, closeDate),
      };
    });

    const allBookingWindowPenalties = await this.getAllBookingPenalties(
      weeksWithOccupancy,
      goLiveDate,
      calendarUUID,
      true,
    );

    return weeksWithOccupancy.map((week, index) => {
      const bookingWindowPenalty = allBookingWindowPenalties[index];
      return {
        ...week,
        penalty: bookingWindowPenalty, // TODO: remove when Engine results are validated

        commercialOccupancy: computeCommercialOccupancy(
          week.occupancyRange,
          week.open,
          bookingWindowPenalty.penalty,
          week.occupancyOverride,
        ),
      };
    });
  }

  protected async selectOccupancyCascade(
    regionCode: string,
    cascadeTypeUUID: string,
  ): Promise<IOccupancyCascade[]> {
    // Get the list of seasons from the price cascade
    const cascadeData = await this.getDBCascadeDataForUUID(cascadeTypeUUID);
    const allSeasons = await this.getSeasons();
    const myCalendarSeasons: ISeason[] = cascadeData.map((cascade) => {
      const season = allSeasons.find((s) => s.uuid === cascade.season);
      if (!season) {
        throw new PricingEngineException(
          `Could not find a season (${cascade.season}) for the selected cascade`,
        );
      }
      return season;
    });

    const occupancyCascadeCoeffs = await this.getOccupancySeasonCoefficients(regionCode);

    // Map the occupancy cascade to the seasons
    const occupancyCascade = myCalendarSeasons.map((season) => {
      const cascadeCoeff = occupancyCascadeCoeffs.find(
        ({ featureName }) => featureName === `season_type_id${season.name.toLowerCase()}`,
      );
      if (!cascadeCoeff) {
        return {
          season,
          value: -1, // This nullifies the coefficient for later in the engine
        };
      }
      return {
        season,
        value: cascadeCoeff.value,
      };
    });

    return occupancyCascade;
  }
}

export default NovasolOccupancyEngine;
