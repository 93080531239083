import { INewProperty, IUpstreamProperty } from 'services/appDatabase';
import ShortBreakEngine from 'services/engine/shortBreak';
import {
  IPricingResult,
  IShortBreakEngine,
  IShortBreakResult,
  IStayTypeValue,
} from 'services/engine/types';

class NovasolShortBreakEngine extends ShortBreakEngine implements IShortBreakEngine {
  // Novasol does not need compute short break
  async computeShortBreak(
    newProperty: INewProperty,
    benchmarkProperties: IUpstreamProperty[],
    pricingResults: IPricingResult[],
    adjustedPriceDistribution?: IStayTypeValue[] | undefined,
  ): Promise<IShortBreakResult> {
    return {
      arrivalDistribution: [],
      weeks: [],
      priceDistribution: [],
    };
  }
}

export default NovasolShortBreakEngine;
