import { QuoteQueueStatus, QuoteStatus } from 'constants/quotes';
import Dexie from 'dexie';
import { DateTime } from 'luxon';
import { ISeasonPrices } from 'pages/Quote/Quote';
import { AdjustedResult } from 'stateManagement/Engine';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import {
  IAveragePriceChangeResult,
  IMarkupResult,
  IOccupancyResult,
  IPricingResult,
  IRevenueResult,
  IShortBreakResult,
} from './engine/types';

export interface IAppDatabase {
  upstreamProperties: Dexie.Table<IUpstreamProperty, string>;
  newProperties: Dexie.Table<INewPropertyQuote, string>;
  sectors: Dexie.Table<ISector, string>;
  occupancyCoefficients: Dexie.Table<IOccupancyCoefficient, number>;
  features: Dexie.Table<IFeature, string>;
  bookingPerMonth: Dexie.Table<IBookingPerMonth, number>;
  bookingWindowPenalty: Dexie.Table<IBookingWindowPenalty, number>;
  weekMapping: Dexie.Table<IWeekMapping, string>;
  season: Dexie.Table<ISeason, string>;
  priceCoefficient: Dexie.Table<IPriceCoefficient, string>;
  bookingProbability: Dexie.Table<IBookingProbability, number>;
  pricingRegion: Dexie.Table<IPricingRegion, number>;
  shortBreak: Dexie.Table<IShortBreakArrivalDistribution, number>;
  calendar: Dexie.Table<ICalendar, string>;
  countries: Dexie.Table<ICountry, string>;
  countryRegions: Dexie.Table<ICountryRegion, string>;
  commissionRate: Dexie.Table<ICommissionRate, string>;
  markupHomeInsurance: Dexie.Table<IMarkupHomeInsurance, string>;
  markupSeasonalExtras: Dexie.Table<IMarkupSeasonalExtras, string>;
  areaCode: Dexie.Table<IAreaCode, string>;
  cascadeTypes: Dexie.Table<ICascadeType, string>;
  cascadeData: Dexie.Table<ICascadeData, string>;
  currencies: Dexie.Table<ICurrency, string>;
  priceIdMappings: Dexie.Table<IPriceMapping, string>;
  markupCurrencyFee: Dexie.Table<ICurrencyFee, string>;
  markupData: Dexie.Table<IMarkupData, string>;
  markupMappings: Dexie.Table<IMarkupMapping, string>;
}

export class AppDatabase extends Dexie implements IAppDatabase {
  upstreamProperties: Dexie.Table<IUpstreamProperty, string>;
  newProperties: Dexie.Table<INewPropertyQuote, string>;
  sectors: Dexie.Table<ISector, string>;
  occupancyCoefficients: Dexie.Table<IOccupancyCoefficient, number>;
  features: Dexie.Table<IFeature, string>;
  bookingPerMonth: Dexie.Table<IBookingPerMonth, number>;
  bookingWindowPenalty: Dexie.Table<IBookingWindowPenalty, number>;
  weekMapping: Dexie.Table<IWeekMapping, string>;
  season: Dexie.Table<ISeason, string>;
  priceCoefficient: Dexie.Table<IPriceCoefficient, string>;
  guestPriceCoefficient: Dexie.Table<IPriceCoefficient, string>;
  bookingProbability: Dexie.Table<IBookingProbability, number>;
  pricingRegion: Dexie.Table<IPricingRegion, number>;
  shortBreak: Dexie.Table<IShortBreakArrivalDistribution, number>;
  calendar: Dexie.Table<ICalendar, string>;
  countries: Dexie.Table<ICountry, string>;
  countryRegions: Dexie.Table<ICountryRegion, string>;
  commissionRate: Dexie.Table<ICommissionRate, string>;
  markupHomeInsurance: Dexie.Table<IMarkupHomeInsurance, string>;
  markupSeasonalExtras: Dexie.Table<IMarkupSeasonalExtras, string>;
  areaCode: Dexie.Table<IAreaCode, string>;
  cascadeTypes: Dexie.Table<ICascadeType, string>;
  cascadeData: Dexie.Table<ICascadeData, string>;
  currencies: Dexie.Table<ICurrency, string>;
  priceIdMappings: Dexie.Table<IPriceMapping, string>;
  markupCurrencyFee: Dexie.Table<ICurrencyFee, string>;
  markupData: Dexie.Table<IMarkupData, string>;
  markupMappings: Dexie.Table<IMarkupMapping, string>;

  allTables: Array<
    Dexie.Table<
      | IProperty
      | INewPropertyQuote
      | ISector
      | IOccupancyCoefficient
      | IFeature
      | IBookingPerMonth
      | IBookingWindowPenalty
      | IWeekMapping
      | ISeason
      | IPriceCoefficient
      | IBookingProbability
      | IPricingRegion
      | IShortBreakArrivalDistribution
      | ICalendar
      | ICountry
      | ICountryRegion
      | ICommissionRate
      | IMarkupHomeInsurance
      | IMarkupSeasonalExtras
      | IAreaCode
      | ICascadeType
      | ICascadeData
      | ICurrency
      | IPriceMapping
      | ICurrencyFee
      | IMarkupData
      | IMarkupMapping,
      string | number
    >
  >;

  constructor() {
    super('AppDatabase');
    this.version(1).stores({
      upstreamProperties: 'uuid,noOfBedrooms',
      newProperties: 'uuid',
      sectors: 'sector',
      features: 'name',
      occupancyCoefficients: '++, regionName',
      bookingPerMonth: '++, regionCode',
      bookingWindowPenalty: '++,[departMonth+leadTime]',
    });
    this.version(2).stores({ weekMapping: '[currentYear+currentWeek]' });
    this.version(3).stores({ season: 'uuid' });
    this.version(4).stores({
      calendarEntry: '++, calendarName, [season+weekNumber+calendarName]',
      weekMapping: '++, [currentYear+currentWeek]',
    });
    this.version(5).stores({ priceCoefficient: '++, regionCode' });
    this.version(6).stores({ bookingProbability: '++, [regionCode+year]' });
    this.version(7).stores({ weekMapping: '++, [currentYear+bookingType]' });
    this.version(8).stores({ bookingProbability: '++, regionCode' });
    this.version(9).stores({ region: '++, code' });
    this.version(10).stores({ shortBreak: '++, [regionCode+bedrooms]' });
    this.version(11).stores({ calendar: 'uuid, name' });
    this.version(12).stores({
      weekMapping: '++, [currentYear+bookingType+calendarName]',
      calendarEntry: null,
    });
    this.version(13).stores({ propertyTypes: '++' });
    this.version(14).stores({ countries: 'uuid, code' });
    this.version(15).stores({ countryRegions: 'uuid' });
    this.version(16).stores({ markup: 'uuid' });
    this.version(17).stores({
      region: null,
      pricingRegion: '++, code',
      shortBreak: '++, [pricingRegionCode+bedrooms]',
      bookingProbability: '++, pricingRegionCode',
      priceCoefficient: '++, pricingRegionCode',
      occupancyCoefficients: '++, pricingRegionName',
      bookingPerMonth: '++, pricingRegionCode',
    });
    this.version(18).stores({ cascadeTypes: 'uuid,calendar' });
    this.version(19).stores({ cascadeData: 'uuid,cascadeType' });
    this.version(20).stores({ occupancyCoefficients: '++, pricingRegionCode' });
    this.version(21).stores({ currencies: 'uuid' });
    this.version(22).stores({ newProperties: 'uuid,status' });
    this.version(23).stores({ newProperties: 'uuid, status, [queueStatus+status]' });
    this.version(24).stores({ bookingWindowPenalty: '++,[departMonth+leadTime+calendar]' });
    this.version(25).stores({ propertyTypes: null });
    this.version(26).stores({ newProperties: 'uuid, status, [queueStatus+status], queueStatus' });
    this.version(27).stores({ cascadeTypes: 'uuid,calendar,name' });
    this.version(28).stores({
      markupHomeInsurance: 'uuid',
      markupSeasonalExtras: 'uuid',
      areaCode: 'uuid',
    });
    this.version(29).stores({ markup: null, commissionRate: 'uuid' });
    this.version(30).stores({ priceIdMappings: 'uuid' });
    this.version(31).stores({ markupCurrencyFee: 'uuid' });
    this.version(32).stores({ markupData: 'uuid, markupId' });
    this.version(33).stores({ markupMappings: '++' });
    this.version(34).stores({ countryRegions: 'uuid, name' });
    this.version(35).stores({ cascadeTypes: 'uuid,calendar,name,year' });
    this.version(36).stores({ guestPriceCoefficient: '++, pricingRegionCode' });

    this.upstreamProperties = this.table('upstreamProperties');
    this.newProperties = this.table('newProperties');
    this.sectors = this.table('sectors');
    this.occupancyCoefficients = this.table('occupancyCoefficients');
    this.features = this.table('features');
    this.bookingPerMonth = this.table('bookingPerMonth');
    this.bookingWindowPenalty = this.table('bookingWindowPenalty');
    this.weekMapping = this.table('weekMapping');
    this.season = this.table('season');
    this.priceCoefficient = this.table('priceCoefficient');
    this.guestPriceCoefficient = this.table('guestPriceCoefficient');
    this.bookingProbability = this.table('bookingProbability');
    this.pricingRegion = this.table('pricingRegion');
    this.shortBreak = this.table('shortBreak');
    this.calendar = this.table('calendar');
    this.countries = this.table('countries');
    this.countryRegions = this.table('countryRegions');
    this.commissionRate = this.table('commissionRate');
    this.markupHomeInsurance = this.table('markupHomeInsurance');
    this.markupSeasonalExtras = this.table('markupSeasonalExtras');
    this.areaCode = this.table('areaCode');
    this.cascadeTypes = this.table('cascadeTypes');
    this.cascadeData = this.table('cascadeData');
    this.currencies = this.table('currencies');
    this.priceIdMappings = this.table('priceIdMappings');
    this.markupCurrencyFee = this.table('markupCurrencyFee');
    this.markupData = this.table('markupData');
    this.markupMappings = this.table('markupMappings');

    this.allTables = [
      this.areaCode,
      this.bookingPerMonth,
      this.bookingProbability,
      this.bookingWindowPenalty,
      this.calendar,
      this.cascadeData,
      this.cascadeTypes,
      this.commissionRate,
      this.countries,
      this.countryRegions,
      this.currencies,
      this.features,
      this.markupCurrencyFee,
      this.markupData,
      this.markupHomeInsurance,
      this.markupSeasonalExtras,
      this.newProperties,
      this.occupancyCoefficients,
      this.priceCoefficient,
      this.priceIdMappings,
      this.pricingRegion,
      this.season,
      this.sectors,
      this.shortBreak,
      this.upstreamProperties,
      this.weekMapping,
      this.markupMappings,
    ];
  }

  clearDB = () => Promise.all(this.allTables.map((table) => table.clear()));

  clearDBExceptQuotes = () =>
    Promise.all(
      this.allTables.map((table) => {
        if (table.name.toLowerCase() !== 'newproperties') {
          return table.clear();
        }
        return Promise.resolve();
      }),
    );
}

export type UUID = string;

export interface IProperty {
  featureFactors: FeatureValueSet;
  grade: number;
  postcode: string;
}

// eslint-disable-next-lineno-empty-interface
export type IAreaCode = IUUIDName;

export interface ICurrentUser {
  displayName: string;
  firstName: string;
  lastName: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  phoneNumber: string;
  email: string;
}

export interface IUpstreamProperty extends IProperty {
  uuid: string;
  serviceId: number;
  createdAt: string;
  updatedAt: string;
  distance?: number;
  realizedPrices: IRealizedPrice[];
  longitude: string;
  latitude: string;
  commercialOccupancy: number;
  propertyType: string;
  novasolSeasonAGuestPrice: number | null;

  // Property features
  buildYear: number;
  renovationYear: number;
  noOfBedrooms: number;
  noOfBaths: number;
  maxPersons: number;
  sizeOfProperty: number;
  poolCode: number;
  whirlpoolCode: number;
  balconyCode: number;
  plotCode: number;

  // Location features
  distanceToSeaInKm: number;
  viewCode: number;

  // Concepts
  allInclusiveYN: boolean;
  petsAllowedYN: boolean;
  activityHouseYN: boolean;
  ownerLivesOnPlotYN: boolean;
  acCode: string;
  energyCode: number;
  fishHouseCode: string;
  germanTvChannelsYN: boolean;
  antennaCode: number;
  saunaCode: number;
  internetCode: string;
}

export interface ISeasonalSummary {
  season: string;
  high: number;
  low: number;
}

interface INewPropertyBase extends IProperty {
  uuid: string;
  leadId: string;
  contractNumber: string;
  lastName: string;
  firstName: string;
  econtractId: string | undefined;
  propertyName: string;
  bedrooms: number;
  bathrooms: number;
  guests: number;
  persisted: boolean;
  updatedAt: string;
  benchmarkProperties: string[];
  status: QuoteStatus;
  queueStatus: QuoteQueueStatus;
  ownerWeeks: IOwnerWeeks;
  houseNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  propertyType: string;
  longitude: number | null;
  latitude: number | null;
  areaCode: string | null;
  features: IBrandFeatures;
  countryRegion: string;
  subRegion: string;
  pricingRegionCode: string | null;
  errorMessage: string | null;
  salesforceUpdated: string | null;
  salesforceUpdateErrors: any[] | null;
}

export interface INewProperty extends INewPropertyBase {
  firstAvailable: DateTime;
  goLive: DateTime;
  availabilityOpenDate: string;
  availabilityCloseDate: string;
  calendar: string;
  cascadeType: string;
}

export interface INewPropertyQuote extends INewPropertyBase {
  floorPrice: number | null;
  pricingResults: AdjustedResult<IPricingResult[]>;
  shortBreakResults: AdjustedResult<IShortBreakResult>;
  occupancies: AdjustedResult<IOccupancyResult[]>;
  revenues: IRevenueResult[];
  apcResults: IAveragePriceChangeResult[];
  markups: AdjustedResult<IMarkupResult>;
  firstAvailable: string;
  goLive: string;
  commissionRate: number;
  minCommissionRate: number;
  defaultCommissionRate: number;
  availabilityOpenDate: string | null;
  availabilityCloseDate: string | null;
  calendar: string;
  cascadeType: string;
  newSeasonPrices: ISeasonPrices;
  seasonPrices?: ISeasonPrices;
}

export interface IFeatureValue {
  featureName: string;
  value: number;
}

export type FeatureValueSet = IFeatureValue[];

export interface ILatLon {
  latitude: number;
  longitude: number;
}

export interface ISector extends ILatLon {
  sector: string;
  pricingRegionName: string;
  pricingRegionCode: string;
  lengthOfStay: number;
}

export interface IOccupancyCoefficient extends IFeatureValue {
  pricingRegionCode: string;
}

export interface IPricingCoefficients {
  season: string;
  coefficients: FeatureValueSet;
}

export interface IFeature {
  name: string;
}

export interface IBookingPerMonth {
  pricingRegionCode: string;
  percentageOfNights: number;
  month: number;
}

export interface IBookingWindowPenalty {
  departMonth: number;
  leadTime: number;
  penalty: number;
  calendar: string;
}

export interface IBookingProbability {
  pricingRegionCode: string;
  year: number;
  weekNumber: number;
  bookingProbability: number;
}

export interface IWeekMapping {
  previousWeek: number;
  previousYear: number;
  currentYear: number;
  currentWeek: number;
  currentMonth: number;
  bookingType: string;
  calendarName: string;
  season: string;
  weekOccupancyOverride: boolean;
}

export interface IUUIDName {
  uuid: string;
  name: string;
}

export interface ISeason extends IUUIDName {
  color: string;
}

export type IOwnerWeeks = null | IOwnerWeek[];

export interface IOwnerWeek {
  weekNumber: number;
  blocked: boolean;
}

export interface IPriceCoefficient {
  season: string;
  pricingRegionCode: string;
  featureName: string;
  coefficient: number;
}

export interface IRealizedPrice {
  weekNumber: number;
  year: number;
  price: number;
  midweekRatio: number;
  weekendRatio: number;
}

export interface IPricingRegion {
  code: string;
  discount: number;
  lengthOfStay: number;
  name: string;
  year1MarketRate: number;
  year2MarketRate: number;
}

export interface IShortBreakArrivalDistribution {
  uuid?: string;
  pricingRegionCode: string;
  bedrooms: number;
  factor: number;
  type: IShortBreakType;
}

export interface IShortBreakType {
  uuid?: string;
  nights: number;
  type: 'midweek' | 'weekend' | 'fullweek';
}

export interface ICalendarShortBreakPriceDistribution {
  uuid: string;
  type: IShortBreakType;
  value: number;
}

export interface ICalendar extends IUUIDName {
  distribution: ICalendarShortBreakPriceDistribution[];
  elasticity: number;
}

export interface ICountry extends IUUIDName {
  code: string;
  taxRate: number;
  currency: string;
  guestCurrency: string;
  twoDigitCode: string;
  marketSize: 'Mid' | 'Major';
  useGuestPrice: boolean;
  useGuestPriceCoefficients: boolean;
  disableReasonDialog: boolean;
}

export interface ISubRegion extends IUUIDName {
  countryRegion: string;
  pricingRegion: string;
  feature: string | null; // the SubRegion is not linked to a feature anymore for Novasol
}

export interface ICountryRegion extends IUUIDName {
  country: string;
  subRegions: ISubRegion[];
}

export interface ICommissionRate {
  country: string;
  value: number;
  minValue: number;
}

export interface ICascadeType extends IUUIDName {
  calendar: string;
  year: number;
}
export interface ICascadeData {
  uuid: string;
  value: number;
  cascadeType: string;
  season: string;
}

export interface Currencies {
  ownerCurrency: ICurrency;
  guestCurrency: ICurrency;
}

export interface ICurrency extends IUUIDName {
  code: string;
  isDefaultForSite: boolean;
  conversionRateToDefault: number;
  prefix: string;
  suffix: string;
}

export interface IMarkupHomeInsurance {
  productMarketCountryCode: string;
  productMarketCurrencyCode: string;
  salesMarketCountryCode: string;
  salesMarketCurrencyCode: string;
  year: number;
  uuid: string;
  price: number;
}

export interface IMarkupSeasonalExtras {
  productMarketCountryCode: string;
  productMarketCurrencyCode: string;
  calendar: string;
  season: string;
  year: number;
  uuid: string;
  priceId: string;
  cancellationInsurancePercentage: number;
  minorDamages: number;
}

export interface IPriceMapping {
  uuid: UUID;
  priceId: string;
  calendar: UUID;
}

export interface ICurrencyFee {
  uuid: UUID;
  productMarketCurrency: string;
  salesMarketCurrency: string;
  conversion: number;
}

export const dummyCurrencyFee: ICurrencyFee = {
  uuid: '',
  productMarketCurrency: '',
  salesMarketCurrency: '',
  conversion: 1,
};

export interface IMarkupValue {
  markup: number;
  seasonName: string;
}

export interface IMarkupData extends IMarkupValue {
  salesMarketCountryCode: string;
  markupId: string;
  year: number;
  rangeLow: number;
  rangeHigh: number;
  houseType: number;
  areaCodeName: string;
  uuid: UUID;
}

export interface IMarkupMapping {
  productMarketCountryCode: string;
  cascadeTypeName: string;
  markupId: string;
  year: number;
}

export default new AppDatabase();
