import {
  FORMER_FARM_HOUSE,
  HOLIDAY_APARTEMENT,
  HOLIDAY_COTTAGE,
  PROPERTY_TYPE_UPSTREAM_VALUES,
  SEMI_DETACHED,
  TERRACED_HOUSE,
} from 'constants/propertyTypes';

const PROPERTY_TYPE_MAPPPING: { [key: string]: string } = {
  [HOLIDAY_APARTEMENT]: PROPERTY_TYPE_UPSTREAM_VALUES.holidayApartement.value,
  [FORMER_FARM_HOUSE]: PROPERTY_TYPE_UPSTREAM_VALUES.holidayHouse.value,
  [SEMI_DETACHED]: PROPERTY_TYPE_UPSTREAM_VALUES.semiDetachedHouse.value,
  [TERRACED_HOUSE]: PROPERTY_TYPE_UPSTREAM_VALUES.semiDetachedHouse.value,
  [HOLIDAY_COTTAGE]: PROPERTY_TYPE_UPSTREAM_VALUES.holidayHouse.value,
};

export const getPropertyTypeValue = (propertyTypeName: string) =>
  PROPERTY_TYPE_MAPPPING[propertyTypeName];
