import { INewSeasonPriceForYear, ISeasonPrices } from 'pages/Quote/Quote';
import { ICurrency } from 'services/appDatabase';
import { AdjustedResult } from 'stateManagement/Engine';
import BaseEngine from './base';
import { PricingEngineException } from './exceptions';
import { IPricingResult, ISeasonPricingEngine, IWeekPricingData } from './types';

interface IWeekDataWithSeason {
  price: number;
  salesPrice?: number;
  year?: number;
  season: string;
  currency: string;
}

class SeasonPricingEngine extends BaseEngine implements ISeasonPricingEngine {
  async computeSeasonPricesN2S(
    pricingResults: AdjustedResult<IPricingResult[]>,
    ownerCurrency: ICurrency,
  ): Promise<ISeasonPrices> {
    const pricingData: IPricingResult[] = pricingResults.adjusted
      ? pricingResults.adjusted.data
      : pricingResults.engine.data;

    return {
      yearOne: await this.seasonPricesForYearN2S(pricingData[0], ownerCurrency),
      yearTwo: await this.seasonPricesForYearN2S(pricingData[1], ownerCurrency),
    };
  }

  protected seasonPricesForYearN2S = async (
    pricingData: IPricingResult,
    ownerCurrency: ICurrency,
  ) => {
    const yearPrices = await Promise.all(
      pricingData.yearData.map(async (weekData: IWeekPricingData) => {
        const season = await this.getSeasonForUUID(weekData.season);
        if (!season) {
          throw new PricingEngineException(
            `Could not find a season for the uuid ${weekData.season}`,
          );
        }
        // Align to AS400 behaviour (round down)
        const price = Math.floor(weekData.price * ownerCurrency.conversionRateToDefault);
        const salesPrice = Math.floor(
          (weekData.salesPrice ?? 0) * ownerCurrency.conversionRateToDefault,
        );
        return {
          price,
          salesPrice,
          season: season.name,
          year: weekData.year,
          currency: ownerCurrency.code,
        };
      }),
    );
    return yearPrices.reduce(this.reduceSeasonsN2S, {});
  };

  protected reduceSeasonsN2S = (
    accumulator: INewSeasonPriceForYear,
    weekDataWithSeason: IWeekDataWithSeason,
  ) => {
    accumulator[weekDataWithSeason.season] = {
      value: weekDataWithSeason.price,
      salesValue: weekDataWithSeason.salesPrice,
      isocodecurrency: weekDataWithSeason.currency,
      year: weekDataWithSeason.year,
      season: weekDataWithSeason.season,
    };
    return accumulator;
  };

  async computeSeasonPrices(
    pricingResults: AdjustedResult<IPricingResult[]>,
    ownerCurrency: ICurrency,
  ): Promise<ISeasonPrices> {
    const pricingData: IPricingResult[] = pricingResults.adjusted
      ? pricingResults.adjusted.data
      : pricingResults.engine.data;

    return {
      yearOne: await this.seasonPricesForYear(pricingData[0], ownerCurrency),
      yearTwo: await this.seasonPricesForYear(pricingData[1], ownerCurrency),
    };
  }

  protected seasonPricesForYear = async (pricingData: IPricingResult, ownerCurrency: ICurrency) => {
    const yearPrices = await Promise.all(
      pricingData.yearData.map(async (weekData: IWeekPricingData) => {
        const season = await this.getSeasonForUUID(weekData.season);
        if (!season) {
          throw new PricingEngineException(
            `Could not find a season for the uuid ${weekData.season}`,
          );
        }
        // Align to AS400 behaviour (round down)
        const price = Math.floor(weekData.price * ownerCurrency.conversionRateToDefault);
        return {
          price,
          season: season.name,
          currency: ownerCurrency.code,
          year: weekData.year,
        };
      }),
    );
    return yearPrices.reduce(this.reduceSeasons, {});
  };

  protected reduceSeasons = (
    accumulator: INewSeasonPriceForYear,
    weekDataWithSeason: IWeekDataWithSeason,
  ) => {
    accumulator[weekDataWithSeason.season] = {
      value: weekDataWithSeason.price,
      salesValue: weekDataWithSeason.salesPrice,
      isocodecurrency: weekDataWithSeason.currency,
      year: weekDataWithSeason.year,
      season: weekDataWithSeason.season,
    };
    return accumulator;
  };
}

export default SeasonPricingEngine;
