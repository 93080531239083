import { MARKET_SIZE } from 'constants/countries';
import {
  COTTAGES_CALENDAR,
  DEFAULT_FULLWEEK_LENGTH,
  DEFAULT_MIDWEEK_LENGTH,
  DEFAULT_WEEKEND_LENGTH,
} from 'constants/engine';
import _range from 'lodash/range';
import { BIG_PROPERTIES, BIG_PROPERTIES_IDENTIFIER } from 'services/engine/constants';
import { getPropertyTypeValue } from 'services/form/propertyTypes';
import {
  AppDatabase,
  FeatureValueSet,
  IBookingProbability,
  ICalendar,
  ICalendarShortBreakPriceDistribution,
  ICascadeData,
  ICascadeType,
  ICountry,
  ICountryRegion,
  IFeatureValue,
  INewProperty,
  IPricingRegion,
  IProperty,
  ISeason,
  ISector,
  IShortBreakArrivalDistribution,
  IUpstreamProperty,
} from '../appDatabase';
import { PricingEngineException } from './exceptions';
import { IShortBreakWeekRatio } from './shortBreak';
import { ICascadeTypeIndex, IPricingResult, IStayTypeValue, IWeekStayTypeBreakdown } from './types';
import {
  commercialRounding,
  getPostcodeSector,
  getYearsToTest,
  roundEstimatedPrice,
  selectWeekMappingsForYearAndCalendar,
  weeksInYear,
} from './utils';

const RANGE_PERCENTAGES = {
  novasol: {
    highest: 0.2,
    lowest: 0.1,
  },
  cottages: {
    highest: 0.2,
    lowest: 0.2,
  },
};

class BaseEngine {
  static getLowRangePrice(midlinePrice: number): number {
    return roundEstimatedPrice(midlinePrice, -RANGE_PERCENTAGES[window.config.site].lowest, 1);
  }

  static getHighRangePrice(midlinePrice: number): number {
    return roundEstimatedPrice(midlinePrice, RANGE_PERCENTAGES[window.config.site].highest, 1);
  }

  protected db: AppDatabase;
  protected yearlyIncrease = 0.05;

  constructor(database: AppDatabase) {
    this.db = database;
  }

  public async getPriceDistribution(): Promise<IStayTypeValue[]> {
    const calendar = await this.getCalendar();

    return calendar.distribution.map((distribution: ICalendarShortBreakPriceDistribution) => {
      const type = distribution.type.type;

      return {
        type,
        nights: distribution.type.nights,
        value: distribution.value,
      };
    });
  }

  public async getDBCascadeTypeForUUID(cascadeTypeUUID: string): Promise<ICascadeType | undefined> {
    return this.db.cascadeTypes.get(cascadeTypeUUID);
  }

  public async getDBCascadeDataForUUID(cascadeTypeUUID: string): Promise<ICascadeData[]> {
    return this.db.cascadeData.where('cascadeType').equals(cascadeTypeUUID).toArray();
  }

  public async getDBCascadeTypesForNameAndCalendar(
    name: string,
    calendarUUID: string,
  ): Promise<ICascadeType[]> {
    return this.db.cascadeTypes
      .where('name')
      .equals(name)
      .and((cascade) => cascade.calendar === calendarUUID)
      .toArray();
  }

  public async getSeasonForUUID(uuid: string): Promise<ISeason | undefined> {
    return this.db.season.get({ uuid });
  }

  public async getDBCascadeUUIDWithSameNameAs(
    cascadeUUID: string,
    calendarUUID: string,
    currentYear: number,
  ): Promise<string> {
    const cascadeType = await this.db.cascadeTypes.get(cascadeUUID);
    if (!cascadeType) {
      throw new PricingEngineException(`No cascadeType for cascadeTypeUUID ${cascadeUUID}`);
    }
    const cascadeTypes = await this.db.cascadeTypes
      .where('name')
      .equals(cascadeType.name)
      .sortBy('year');

    const cascadeTypeByYear: ICascadeTypeIndex = cascadeTypes
      .filter((cascade) => cascade.calendar === calendarUUID)
      .reduce(
        (cascadeIndex, cascade) => ({
          ...cascadeIndex,
          [cascade.year]: cascade,
        }),
        {},
      );

    const MAX_YEARS = 3;
    const yearsToTest = getYearsToTest(currentYear, MAX_YEARS);

    for (const year of yearsToTest) {
      const latestCascadeType = cascadeTypeByYear[year];
      if (latestCascadeType) {
        return latestCascadeType.uuid;
      }
    }

    throw new PricingEngineException(
      `No cascade types (${cascadeType.name}) found between between ${Math.min(
        ...yearsToTest,
      )} and ${Math.max(...yearsToTest)}`,
    );
  }

  public async getDBCascadeUUID(
    name: string,
    calendar: string,
    year: number,
  ): Promise<string | undefined> {
    const cascadeType = await this.db.cascadeTypes.get({ name, calendar, year });
    return cascadeType ? cascadeType!.uuid : undefined;
  }

  protected async getSeasons() {
    return await this.db.season.toArray();
  }

  protected async getOccupancySeasonCoefficients(regionCode: string) {
    return await this.db.occupancyCoefficients
      .where('pricingRegionCode')
      .equals(regionCode)
      .and((coeff) => coeff.featureName.startsWith('season_type_id'))
      .toArray();
  }

  protected mapToFeatureValues(
    property: IProperty,
    coefficients: FeatureValueSet,
  ): FeatureValueSet {
    return property.featureFactors.map((featureValue) => {
      const featureCoefficient = coefficients.find(
        (coeff) => coeff.featureName === featureValue.featureName,
      );
      return {
        featureName: featureValue.featureName,
        value: featureCoefficient ? featureCoefficient.value * featureValue.value : 0,
      };
    });
  }

  protected sumOfFeatureValueSet = (featureValueSet: FeatureValueSet) =>
    featureValueSet.reduce((acc, value: IFeatureValue) => acc + value.value, 0);

  protected async getSector(postcode: string): Promise<ISector> {
    const sector = getPostcodeSector(postcode);
    const sectorData = await this.db.sectors.where('sector').equals(sector).first();
    if (!sectorData) {
      throw new PricingEngineException(
        `No sector data found in database for postcode "${postcode}"`,
      );
    }
    return sectorData;
  }

  protected getYears(property: INewProperty): number[] {
    const yearOne = property.firstAvailable.year;
    const yearTwo = yearOne + 1;
    const yearThree = yearTwo + 1;
    return [yearOne, yearTwo, yearThree];
  }

  protected async getShortBreakArrivalDistribution(
    pricingRegionCode: string,
    bedrooms: number,
  ): Promise<IShortBreakArrivalDistribution[]> {
    const shortBreak = await this.db.shortBreak
      .where('[pricingRegionCode+bedrooms]')
      .equals([pricingRegionCode, bedrooms])
      .and((shortbreak) => ['weekend', 'midweek'].includes(shortbreak.type.type));

    if (!shortBreak) {
      throw new PricingEngineException(
        `No short break data found in database for pricing region code: ${pricingRegionCode} and bedrooms: ${bedrooms}`,
      );
    }

    return shortBreak.toArray();
  }

  protected async selectCalendar(calendarUUID: string) {
    return await this.db.calendar.get(calendarUUID);
  }

  protected async getCalendars() {
    return await this.db.calendar.toArray();
  }

  protected selectBookingProbabilities(pricingRegionCode: string): Promise<IBookingProbability[]> {
    return this.db.bookingProbability
      .where('pricingRegionCode')
      .equals(pricingRegionCode)
      .toArray();
  }

  protected getCalendarName(): string {
    return COTTAGES_CALENDAR;
  }

  protected async getCalendar(): Promise<ICalendar> {
    const calendarName = this.getCalendarName();
    const calendars = await this.getCalendars();

    if (!calendars) {
      throw new PricingEngineException(`No calendars found`);
    }

    const calendar = calendars.find((cal) => cal.name === calendarName);
    if (!calendar) {
      throw new PricingEngineException(`No calendar data found for ${calendarName}`);
    }
    return calendar;
  }

  protected async getWeeksBreakdownForYear(
    pricingResults: IPricingResult[],
    benchmarkProperties: IUpstreamProperty[],
    priceDistribution: IStayTypeValue[],
    year: number,
  ): Promise<IWeekStayTypeBreakdown[]> {
    const shortBreakRatiosForYear = await this.getAverageRatiosForYear(benchmarkProperties, year);

    return shortBreakRatiosForYear.map((shortBreakWeek: IShortBreakWeekRatio) => {
      const sameYearPricingResult = pricingResults.find((price) => price.year === year);
      if (!sameYearPricingResult) {
        throw new PricingEngineException(
          `Cannot find a matching short break year ${year} in pricingResults ${pricingResults}`,
        );
      }
      const sameWeek = sameYearPricingResult.yearData.find(
        (priceWeek) => priceWeek.weekNumber === shortBreakWeek.weekNumber,
      );
      if (!sameWeek) {
        throw new PricingEngineException(
          `Cannot find a matching short break year ${shortBreakWeek.weekNumber} in pricingResults ${pricingResults}`,
        );
      }

      const prices: IStayTypeValue[] = priceDistribution.map((distribution: IStayTypeValue) => {
        let price: number;

        switch (distribution.type) {
          case 'fullweek':
            price = sameWeek.price;
            break;
          case 'midweek':
            price = sameWeek.price * shortBreakWeek.midweekRatio;
            break;
          case 'weekend':
            price = sameWeek.price * shortBreakWeek.weekendRatio;
            break;
          default:
            throw new PricingEngineException(
              `[Shortbreak engine] Unknown price distribution type found: ${distribution.type}`,
            );
        }

        return {
          type: distribution.type,
          nights: distribution.nights,
          value: commercialRounding(price * distribution.value),
        };
      });

      if (!prices.find((p) => p.type === 'fullweek' && p.nights === DEFAULT_FULLWEEK_LENGTH)) {
        prices.push({
          type: 'fullweek',
          nights: DEFAULT_FULLWEEK_LENGTH,
          value: commercialRounding(sameWeek.price),
        });
      }
      if (!prices.find((p) => p.type === 'midweek' && p.nights === DEFAULT_MIDWEEK_LENGTH)) {
        prices.push({
          type: 'midweek',
          nights: DEFAULT_MIDWEEK_LENGTH,
          value: commercialRounding(sameWeek.price * shortBreakWeek.midweekRatio),
        });
      }
      if (!prices.find((p) => p.type === 'weekend' && p.nights === DEFAULT_WEEKEND_LENGTH)) {
        prices.push({
          type: 'weekend',
          nights: DEFAULT_WEEKEND_LENGTH,
          value: commercialRounding(sameWeek.price * shortBreakWeek.weekendRatio),
        });
      }

      return {
        weekNumber: shortBreakWeek.weekNumber,
        year: shortBreakWeek.year,
        prices,
        averageMidweekRatio: shortBreakWeek.midweekRatio,
        averageWeekendRatio: shortBreakWeek.weekendRatio,
      };
    });
  }

  protected async getPricingRegionCodeForCottages(property: INewProperty): Promise<string> {
    if (property.pricingRegionCode) {
      return property.pricingRegionCode;
    }

    const sectorData = await this.getSector(property.postcode);
    return sectorData.pricingRegionCode;
  }

  protected async getDBCountryRegion(name: string): Promise<ICountryRegion | undefined> {
    return this.db.countryRegions.where('name').equals(name).first();
  }

  protected async getDBCountry(): Promise<ICountry | undefined> {
    return this.db.countries.toCollection().first();
  }

  protected async getPricingRegionCodeForNovasol(property: INewProperty): Promise<string> {
    const country = await this.getDBCountry();
    if (!country) {
      throw new PricingEngineException(
        `[getPricingRegionCodeForNovasol] Could not find the user country`,
      );
    }

    if (
      country.marketSize === MARKET_SIZE.MAJOR &&
      property.bedrooms >= BIG_PROPERTIES.MIN_BED_NO
    ) {
      const pricingRegion = await this.db.pricingRegion
        .where('code')
        .startsWithIgnoreCase(BIG_PROPERTIES_IDENTIFIER)
        .first();

      if (!pricingRegion) {
        throw new PricingEngineException(
          `No BigProperty pricing region for property in country region uuid: ${property.countryRegion}`,
        );
      }
      return pricingRegion.code;
    }

    const countryRegion = await this.getDBCountryRegion(property.countryRegion);
    if (!countryRegion) {
      throw new PricingEngineException(
        `No country region found in database for uuid: ${property.countryRegion}`,
      );
    }
    if (!property.propertyType) {
      throw new PricingEngineException(
        `[Get Pricing Region Code] No property type saved in your quote`,
      );
    }
    return `${countryRegion.name}${getPropertyTypeValue(property.propertyType)}`.toLowerCase();
  }

  protected async getPricingRegion(newProperty: INewProperty): Promise<IPricingRegion> {
    const pricingRegion = await this.db.pricingRegion.get({
      code: await this.getPricingRegionCodeForNovasol(newProperty),
    });

    if (!pricingRegion) {
      throw new Error("Can't find pricing region for property " + newProperty.uuid);
    }
    return pricingRegion;
  }

  protected async getLengthOfStay(newProperty: INewProperty): Promise<number> {
    const pricingRegion = await this.getPricingRegion(newProperty);
    return pricingRegion.lengthOfStay;
  }

  private async getAverageRatiosForYear(
    benchmarkProperties: IUpstreamProperty[],
    year: number,
  ): Promise<IShortBreakWeekRatio[]> {
    const weekNumbers = _range(1, weeksInYear(year) + 1);
    const buildYear = weekNumbers.map((weekNumber: number) => {
      return {
        weekNumber,
        year,
      };
    });
    const calendar = this.getCalendarName();
    const weekMappings = await selectWeekMappingsForYearAndCalendar(year, calendar);
    const shortBreakWithRatio = buildYear.map((yearData) => {
      const weekMapping = weekMappings.find(
        ({ currentWeek }) => currentWeek === yearData.weekNumber,
      );
      if (!weekMapping) {
        throw new PricingEngineException(
          `Cannot find a week mapping for weekNumber: ${yearData.weekNumber} year: ${year}`,
        );
      }

      const benchmarkRatios = benchmarkProperties.map((property) => {
        const weekData = property.realizedPrices.find(
          (price) => price.weekNumber === weekMapping.previousWeek,
        );
        if (!weekData) {
          // eslint-disable-next-lineno-console
          console.warn(
            `Cannot find a matching week number for ${property.serviceId} for week number: ${yearData.weekNumber}`,
          );
          return {
            weekendRatio: 0,
            midweekRatio: 0,
            commercialOccupancy: 0,
          };
        }

        if (!weekData.weekendRatio || !weekData.midweekRatio) {
          // eslint-disable-next-lineno-console
          console.warn(`Cannot find a weekend ratio or midweek ratio for ${property.serviceId}`);
          return {
            weekendRatio: 0,
            midweekRatio: 0,
            commercialOccupancy: 0,
          };
        }

        return {
          weekendRatio: weekData.weekendRatio,
          midweekRatio: weekData.midweekRatio,
          commercialOccupancy: property.commercialOccupancy,
        };
      });

      const sumOfBenchmarkCommercialOccupancy = benchmarkProperties.reduce(
        (acc, value) => acc + value.commercialOccupancy,
        0,
      );

      const sumOfBenchmarkWeekendRatios = benchmarkRatios.reduce(
        (acc, value) => acc + value.weekendRatio * value.commercialOccupancy,
        0,
      );

      const sumOfBenchmarkMidweekRatios = benchmarkRatios.reduce(
        (acc, value) => acc + value.midweekRatio * value.commercialOccupancy,
        0,
      );

      return {
        ...yearData,
        weekendRatio: sumOfBenchmarkWeekendRatios / sumOfBenchmarkCommercialOccupancy,
        midweekRatio: sumOfBenchmarkMidweekRatios / sumOfBenchmarkCommercialOccupancy,
      };
    });
    return shortBreakWithRatio;
  }
}

export default BaseEngine;
