import { MARKUP_COUNTRY_FALLBACK } from 'constants/countries';
import { ISeasonalPrices, IWeekPricingData } from 'services/engine/types';
import appDatabase, {
  ICountry,
  ICurrency,
  IMarkupSeasonalExtras,
  INewProperty,
} from '../appDatabase';
import { commissionToMarkup, getSeasonsForCalendar } from '../engine/utils';
import {
  getCurrencyRateOfExchange,
  getFilteredMarkupSeasonalExtras,
  getHomeInsuranceValues,
  getPriceMappingForCalendar,
} from './markupCalculator.getters';
import {
  buildSeasonMidlineSalesPriceIndex,
  getCancellationInsurance,
} from './markupCalculator.helpers';

export async function calculateOwnerPrices(
  newProperty: INewProperty,
  ownerCurrency: ICurrency,
  guestCurrency: ICurrency,
  pricingResultsYearOne: IWeekPricingData[],
  commission: number,
): Promise<ISeasonalPrices> {
  const country = await appDatabase.countries.toCollection().first();

  if (country === undefined) {
    throw new Error(`[commissionCalculator] Failed to find country`);
  }

  const markupHomeInsurance = await appDatabase.markupHomeInsurance.toArray();
  const priceMappings = await appDatabase.priceIdMappings.toArray();
  const markupSeasonalExtras = await appDatabase.markupSeasonalExtras.toArray();

  const seasons = await getSeasonsForCalendar(newProperty.calendar, [
    newProperty.firstAvailable.year,
  ]);

  const filteredHomeInsurances = getHomeInsuranceValues(
    country.code,
    MARKUP_COUNTRY_FALLBACK.INSURANCE,
    markupHomeInsurance.filter((i) => i.year === newProperty.firstAvailable.year),
  );

  const filteredPriceMapping = await getPriceMappingForCalendar(
    newProperty.calendar,
    priceMappings,
    newProperty.firstAvailable.year.toString(),
  );
  const filteredSeasonalExtras = getFilteredMarkupSeasonalExtras(
    country.code,
    filteredPriceMapping,
    markupSeasonalExtras,
  );

  // eslint-disable-next-lineno-console
  console.log('filteredHomeInsurances', filteredHomeInsurances);
  // eslint-disable-next-lineno-console
  console.log('filteredSeasonalExtras', filteredSeasonalExtras);

  const seasonMidlinePriceIndexInOwnerCurrency = buildSeasonMidlineSalesPriceIndex(
    seasons,
    pricingResultsYearOne,
  );

  const newOwnerPricesBySeason = Object.keys(seasonMidlinePriceIndexInOwnerCurrency).map(
    (seasonName) => {
      const salesPriceInOwnerCurrency = seasonMidlinePriceIndexInOwnerCurrency[seasonName];
      const newOwnerPriceForSeason = calculateOwnerPriceForSeason(
        seasonName,
        salesPriceInOwnerCurrency,
        commission,
        filteredSeasonalExtras['productMarket'],
        filteredHomeInsurances['productMarket'],
        country,
        ownerCurrency,
      );
      return { seasonName, ownerPrice: newOwnerPriceForSeason, currencyCode: guestCurrency.code };
    },
  );

  const ownerPriceBySeason = newOwnerPricesBySeason.reduce((acc, seasonData) => {
    return {
      ...acc,
      [seasonData.seasonName]: seasonData.ownerPrice,
    };
  }, {});

  return ownerPriceBySeason;
}

export async function calculateOwnerPrice(
  newProperty: INewProperty,
  ownerCurrency: ICurrency,
  guestCurrency: ICurrency,
  salesPriceInOwnerCurrency: number,
  commission: number,
): Promise<number> {
  const country = await appDatabase.countries.toCollection().first();

  if (country === undefined) {
    throw new Error(`[commissionCalculator] Failed to find country`);
  }

  const markupCurrencyFees = await appDatabase.markupCurrencyFee.toArray();
  const markupHomeInsurance = await appDatabase.markupHomeInsurance.toArray();
  const priceMappings = await appDatabase.priceIdMappings.toArray();
  const markupSeasonalExtras = await appDatabase.markupSeasonalExtras.toArray();

  const currencyROE = {
    productMarket: getCurrencyRateOfExchange(ownerCurrency, guestCurrency, markupCurrencyFees),
  };

  const filteredHomeInsurances = getHomeInsuranceValues(
    country.code,
    MARKUP_COUNTRY_FALLBACK.INSURANCE,
    markupHomeInsurance.filter((i) => i.year === newProperty.firstAvailable.year),
  );

  const filteredPriceMapping = await getPriceMappingForCalendar(
    newProperty.calendar,
    priceMappings,
    newProperty.firstAvailable.year.toString(),
  );
  const filteredSeasonalExtras = getFilteredMarkupSeasonalExtras(
    country.code,
    filteredPriceMapping,
    markupSeasonalExtras,
  );

  // eslint-disable-next-lineno-console
  console.log('currencyROE', currencyROE);
  // eslint-disable-next-lineno-console
  console.log('filteredHomeInsurances', filteredHomeInsurances);
  // eslint-disable-next-lineno-console
  console.log('filteredSeasonalExtras', filteredSeasonalExtras);

  const newOwnerPriceForSeason = calculateOwnerPriceForSeason(
    'A',
    salesPriceInOwnerCurrency,
    commission,
    filteredSeasonalExtras['productMarket'],
    filteredHomeInsurances['productMarket'],
    country,
    ownerCurrency,
  );

  return newOwnerPriceForSeason;
}

export const calculateOwnerPriceForSeason = (
  seasonName: string,
  salesPriceInOwnerCurrency: number,
  commission: number,
  filteredSeasonalExtras: IMarkupSeasonalExtras[],
  homeInsurance: number,
  country: ICountry,
  ownerCurrency: ICurrency,
): number => {
  const seasonalExtrasForCurrentSeason = filteredSeasonalExtras.find(
    (markupExtras) => markupExtras.season === seasonName,
  );

  if (!seasonalExtrasForCurrentSeason) {
    throw new Error(`[commissionCalculator] No seasonal extras found for ${seasonName}`);
  }

  const cancellationInsurance = getCancellationInsurance(seasonalExtrasForCurrentSeason);
  const markup = commissionToMarkup(commission);
  const minorDamages = seasonalExtrasForCurrentSeason.minorDamages;
  const salesPrice = salesPriceInOwnerCurrency;

  const isDenmark = country.code === '208';
  const calculation = isDenmark ? danishCalculation : standardCalculation;

  return calculation({
    salesPrice,
    homeInsurance,
    markup,
    cancellationInsurance,
    minorDamages,
    ownerCurrency,
  });
};

type CalculationProps = {
  salesPrice: number;
  homeInsurance: number;
  markup: number;
  cancellationInsurance: number;
  minorDamages: number;
  ownerCurrency: ICurrency;
};

const standardCalculation = ({
  salesPrice,
  homeInsurance,
  markup,
  cancellationInsurance,
  minorDamages,
  ownerCurrency,
}: CalculationProps) => {
  const salesPriceInOwnerCurrency = salesPrice * ownerCurrency.conversionRateToDefault;
  const fixedInsurances = minorDamages + homeInsurance;
  const ownerPrice =
    (salesPriceInOwnerCurrency - fixedInsurances) / (cancellationInsurance * markup);
  const ownerPriceInDKK = ownerPrice / ownerCurrency.conversionRateToDefault;
  return ownerPriceInDKK;
};

const danishCalculation = ({
  salesPrice,
  homeInsurance,
  markup,
  cancellationInsurance,
  minorDamages,
}: CalculationProps) => {
  const salesPriceWithHomeInsurance =
    (salesPrice - minorDamages) / (cancellationInsurance * markup);
  const ownerPrice = salesPriceWithHomeInsurance - homeInsurance;
  return ownerPrice;
};
